import Modal from '@/components/base/Modal/Modal'
import type React from 'react'

interface SelectorPopupProps {
  isOpen: boolean
  children?: React.ReactNode
  onClickModalBackground: () => void
}

export const SelectorPopup = ({
  isOpen,
  children,
  onClickModalBackground
}: SelectorPopupProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClickBackground={onClickModalBackground}>
      {children}
    </Modal>
  )
}
