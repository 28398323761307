import { useApp } from '@/hooks/useApp'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import InputErrorLabel from '../InputErrorLabel'

export interface StyledTextareaProps {
  height?: string
  maxWidth?: string
  width?: string
  disabledColor?: string
  disabled?: boolean
  backgroundColor?: V1AppColorTheme
  isAutoResize?: boolean
  outlineFocus?: string
}

const StyledTextarea = styled.textarea<StyledTextareaProps>`
  width: ${({ width }) => (width !== undefined ? width : '100%')};
  max-width: ${({ maxWidth }) => maxWidth};
  background-color: ${({ backgroundColor }) =>
    backgroundColor?.color != null ? backgroundColor.color : 'white'};
  resize: none;
  box-sizing: border-box;
  height: ${({ height }) => height};
  margin: 0px;
  border: 1px solid #e0e0e0;
  resize: ${({ isAutoResize }) =>
    isAutoResize != null && isAutoResize ? 'none' : 'vertical'};
  overflow: ${({ isAutoResize }) =>
    isAutoResize != null && isAutoResize ? 'hidden' : 'auto'};
  padding: 4px 12px;
  &:focus-visible {
    outline: ${({ outlineFocus }) => outlineFocus};
  }
`
export interface InputTextAreaProps {
  height?: string
  maxWidth?: string
  width?: string
  disabled?: boolean
  disabledColor?: string
  value?: string
  placeholder?: string
  maxLength?: number
  autoResize?: boolean
  errorMessage?: string
  onChange: (value: string) => void
}

const InputTextArea = ({
  height,
  maxWidth,
  width,
  disabled,
  value,
  placeholder,
  maxLength,
  autoResize,
  errorMessage,
  onChange
}: InputTextAreaProps): JSX.Element => {
  const app = useApp()
  const backgroundColor =
    (disabled ?? false) ? app.theme.lightGrayColor : app.theme.transparent

  const isAutoResize = autoResize ?? false
  const textAreaRef = getAutoResizeRef(value)

  const outlineFocusStyle = `${app.theme.primaryMainColor?.color ?? 'black'} solid 1px`

  return (
    <>
      <StyledTextarea
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
        }}
        maxLength={maxLength}
        disabled={disabled}
        width={width ?? '100%'}
        maxWidth={maxWidth}
        backgroundColor={backgroundColor}
        height={height ?? '100%'}
        ref={isAutoResize ? textAreaRef : null}
        isAutoResize={isAutoResize}
        outlineFocus={outlineFocusStyle}
      />
      {errorMessage !== undefined && <InputErrorLabel message={errorMessage} />}
    </>
  )
}

export default InputTextArea

const getAutoResizeRef = (
  value: string | undefined
): React.RefObject<HTMLTextAreaElement> => {
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const element = ref.current
    if (element == null) {
      return
    }
    element.style.height = 'auto' // 行数が減った時に高さを調整する
    element.style.height = `${element.scrollHeight}px`
  }, [value])

  return ref
}
