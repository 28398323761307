import { isMsgScreenType } from '@/utils/constant'
import { handleQueryError } from '@/utils/error'
import {
  ScreenServiceApi,
  type ScreenServiceGetByTypeRequest,
  type ScreenServiceGetJobSeekerMessagesByTypeRequest,
  type V1GetByTypeResponse
} from 'my-recruiter-app-ts-if'
import { config } from './api-config'

export const screenServiceApi = new ScreenServiceApi(config)

export const getScreensByType = async (
  request:
    | ScreenServiceGetByTypeRequest
    | ScreenServiceGetJobSeekerMessagesByTypeRequest
): Promise<V1GetByTypeResponse | undefined> => {
  try {
    return isMsgScreenType(request.screenType)
      ? await screenServiceApi.screenServiceGetJobSeekerMessagesByType(request)
      : await screenServiceApi.screenServiceGetByType(request)
  } catch (err) {
    handleQueryError(err).catch((error) => {
      console.error('Failed to handle error:', error)
    })
  }
}
