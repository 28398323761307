import {
  type V1FormElementState,
  type V1FormElementContentLabelAndState,
  type V1FormElementUiDateSelectBox
} from 'my-recruiter-app-ts-if'
import GenericFormElementSelectBox, {
  type SelectBoxStyle,
  type GenericFormElementSelectBoxOption
} from './GenericFormElementSelectBox'
import { Text3 } from '@/components/base/Text'
import { align, display } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { Block } from '@/components/base/Block'
import InputErrorLabel from '@/components/base/Form/InputErrorLabel'

export interface FormElementDateSelectBoxProps {
  isEdit?: boolean
  uiIFM: V1FormElementUiDateSelectBox
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
  style?: SelectBoxStyle
}

export const generateOptions = (
  options: V1FormElementContentLabelAndState[]
): GenericFormElementSelectBoxOption[] => {
  return (
    options?.map((option) => {
      return {
        label: option.label ?? 'label',
        value: option.state?.uint32 ?? 0
      }
    }) ?? []
  )
}

const createDateArray = (
  year: number,
  month: number
): GenericFormElementSelectBoxOption[] => {
  if (year === 0 || month === 0) {
    return []
  }
  const daysInMonth = new Date(year, month, 0).getDate()
  const months = Array.from({ length: daysInMonth })
  return months.map((_, idx): GenericFormElementSelectBoxOption => {
    return {
      label: `${idx + 1}`,
      value: idx + 1
    }
  })
}

export const FormElementDateSelectBox = ({
  isEdit = true,
  uiIFM,
  formElementState,
  elemenErrorMessage,
  onChange,
  style
}: FormElementDateSelectBoxProps): JSX.Element => {
  const selectedYear =
    formElementState.contentStates?.at(0)?.uint32 ?? undefined
  const selectedMonth =
    formElementState.contentStates?.at(1)?.uint32 ?? undefined
  const selectedDate =
    formElementState.contentStates?.at(2)?.uint32 ?? undefined

  if (!isEdit) {
    const yearSelectedContent = uiIFM.years?.find((e) => {
      return (e.state?.uint32 ?? 0) === selectedYear
    })
    const yearLabel =
      yearSelectedContent != null ? yearSelectedContent.label : '-'

    const monthSelectedContent = uiIFM.months?.find(
      (e) => (e.state?.uint32 ?? 0) === selectedMonth
    )
    const monthLabel =
      monthSelectedContent != null ? monthSelectedContent.label : '-'

    return (
      <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
        {yearLabel}/{monthLabel}/{selectedDate}
      </Text3>
    )
  }

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32_LIST') {
    const yearOptions = generateOptions(uiIFM.years ?? [])
    const monthOptions = generateOptions(uiIFM.months ?? [])
    const dateOptions = createDateArray(selectedYear ?? 0, selectedMonth ?? 0)

    return (
      <>
        <Block
          display={display.GRID}
          style={{
            gridTemplateColumns: `repeat(3, 1fr)`,
            columnGap: spaceStyle.M,
            rowGap: spaceStyle.M
          }}
        >
          <GenericFormElementSelectBox
            key="FormElementDateSelect_year"
            options={yearOptions}
            selectedValue={selectedYear}
            hasError={elemenErrorMessage !== undefined}
            onChange={(selectedValue) => {
              onChange({
                ...formElementState,
                contentStates: [
                  {
                    label: selectedValue?.toString(),
                    uint32: Number(selectedValue)
                  },
                  {
                    label: selectedMonth?.toString(),
                    uint32: selectedMonth
                  },
                  {
                    label: selectedDate?.toString(),
                    uint32: selectedDate
                  }
                ]
              })
            }}
            label={{ right: '年' }}
            style={style}
          />
          <GenericFormElementSelectBox
            key="FormElementDateSelect_month"
            options={monthOptions}
            selectedValue={selectedMonth}
            hasError={elemenErrorMessage !== undefined}
            onChange={(selectedValue) => {
              onChange({
                ...formElementState,
                contentStates: [
                  {
                    label: selectedYear?.toString(),
                    uint32: selectedYear
                  },
                  {
                    label: selectedValue?.toString(),
                    uint32: Number(selectedValue)
                  },
                  {
                    label: selectedDate?.toString(),
                    uint32: selectedDate
                  }
                ]
              })
            }}
            label={{ right: '月' }}
            style={style}
          />
          <GenericFormElementSelectBox
            key="FormElementDateSelect_date"
            options={dateOptions}
            selectedValue={selectedDate}
            hasError={elemenErrorMessage !== undefined}
            onChange={(selectedValue) => {
              onChange({
                ...formElementState,
                contentStates: [
                  {
                    label: selectedYear?.toString(),
                    uint32: selectedYear
                  },
                  {
                    label: selectedMonth?.toString(),
                    uint32: selectedMonth
                  },
                  {
                    label: selectedValue?.toString(),
                    uint32: Number(selectedValue)
                  }
                ]
              })
            }}
            label={{ right: '日' }}
            style={style}
          />
        </Block>
        {elemenErrorMessage !== undefined && (
          <InputErrorLabel message={elemenErrorMessage} />
        )}
      </>
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}
