import type React from 'react'
import { memo, useState } from 'react'
import { device } from '@/styles/device'
import styled from 'styled-components'
import { useApp } from '@/hooks/useApp'
import { Block } from '@/components/base/Block'
import {
  type V1ViewTabBar,
  type V1View,
  V1ViewLayoutType,
  type V1TabBarView,
  type V1SingleView,
  type V1AppColorTheme
} from 'my-recruiter-app-ts-if'
import { Icon, LocalIconType } from '@/components/base/Icon'
import { fontSize, fontWeight } from '@/styles/font'
import { Row } from '@/components/base/Layout'
import { align, display } from '@/styles/display'
import { BaseComponentBuilder } from '@/feature/Builder'
import { FormElementsSelectorBottomContainer } from '@/feature/Builder/Component/containers/FormElementsSelectorPopupContainer/FormElementsSelectorBottomContainer'
import { IconButton } from '@/components/base/Button'
import { Heading2 } from '@/components/base/Heading'
import spaceStyle from '@/styles/space'
import { Text3 } from '@/components/base/Text'

const Container = styled.div`
  width: 100%;
  height: 90vh;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 800px;
  max-height: 700px;
  @media ${device.mobile} {
    border-radius: 0px;
    height: 100%;
    max-height: 10000px;
  }
`

const Scroll = styled.div<{
  borderColor: V1AppColorTheme
  paddingTop?: string
}>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
  max-height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  margin: ${(props) => props.paddingTop ?? '24px'} 24px 0px 24px;

  border-top: 1px solid
    ${(props) => props.borderColor?.color ?? 'rgba(0, 0, 0, 0.12)'};
  border-left: 1px solid
    ${(props) => props.borderColor?.color ?? 'rgba(0, 0, 0, 0.12)'};
  border-right: 1px solid
    ${(props) => props.borderColor?.color ?? 'rgba(0, 0, 0, 0.12)'};
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;

  @media ${device.mobile} {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    margin: 0px;
  }
`

const TabItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 0px;
  flex: 1;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface TabComponentProps {
  tabs: V1ViewTabBar[]
  defaultTabSelected?: number
  onTabViewContent: (index: number) => JSX.Element
}

const TabComponent = ({
  tabs,
  defaultTabSelected,
  onTabViewContent
}: TabComponentProps): JSX.Element => {
  const app = useApp()
  const [selected, setSelected] = useState(defaultTabSelected ?? 0)

  return (
    <Block
      display={display.FLEX}
      backgroundColor={app.theme.lightGrayColor}
      style={{ flexDirection: 'column', flex: 1, overflow: 'hidden' }}
    >
      <Row
        align={align.CENTER}
        height="52px"
        flexWrap="nowrap"
        style={{ overflow: 'hidden' }}
      >
        {tabs.map((e, index) => {
          const color =
            index === selected
              ? (app.theme.whiteTextColor.color ?? 'white')
              : (app.theme.inactiveColor.color ?? 'rgba(0, 0, 0, 0.38)')
          const backgroundColor =
            index === selected
              ? (app.theme.primaryMainColor.color ?? '#1F85FD')
              : (app.theme.lightGrayColor.color ?? 'rgba(0, 0, 0, 0.03)')
          return (
            <TabItem
              key={e.text}
              style={{
                backgroundColor,
                color
              }}
              onClick={() => {
                setSelected(index)
              }}
            >
              {e.iconType !== undefined && (
                <Icon
                  iconType={e.iconType}
                  iconStyle={{
                    fontSize: fontSize.XXL,
                    theme:
                      index === selected
                        ? app.theme.whiteTextColor
                        : app.theme.inactiveColor
                  }}
                />
              )}
              <Text3
                truncate
                fontWeight={fontWeight.Bold}
                textColor={
                  index === selected
                    ? app.theme.whiteTextColor
                    : app.theme.inactiveColor
                }
              >
                {e.text}
              </Text3>
            </TabItem>
          )
        })}
      </Row>
      {onTabViewContent(selected)}
    </Block>
  )
}

interface Props {
  view: V1View
  defaultTabSelected?: number
  onSubmit?: () => void
  onClose: () => void
  backButton?: React.ReactNode
  paddingTop?: string
}

const FormElementsSelectorPopup = ({
  view,
  defaultTabSelected,
  onSubmit,
  backButton,
  paddingTop,
  onClose
}: Props): JSX.Element => {
  const { theme } = useApp()

  let children = <></>
  let bottomView = <></>
  let title: string | undefined

  switch (view.layoutType) {
    case V1ViewLayoutType.SingleView: {
      const { bottom, screen } = view.data as V1SingleView
      bottomView = (
        <FormElementsSelectorBottomContainer
          view={bottom}
          onSubmit={onSubmit}
        />
      )
      title = screen?.title
      children = (
        <Block
          style={{
            flex: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
          backgroundColor={theme.whiteTextColor}
        >
          {backButton}
          <Scroll borderColor={theme.frameColor} paddingTop={paddingTop}>
            {screen?.components?.map((component) => {
              return (
                <BaseComponentBuilder
                  key={component.id}
                  component={component}
                />
              )
            })}
          </Scroll>
        </Block>
      )
      break
    }
    case V1ViewLayoutType.TabBarView: {
      const { tabBars, screens, bottom } = view.data as V1TabBarView
      title = (screens ?? []).map((e) => e.title ?? '').join('/')
      bottomView = (
        <FormElementsSelectorBottomContainer
          view={bottom}
          onSubmit={onSubmit}
        />
      )
      children = (
        <TabComponent
          tabs={tabBars ?? []}
          defaultTabSelected={defaultTabSelected}
          onTabViewContent={(index) => {
            return (
              <Block
                style={{
                  flex: 1,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                backgroundColor={theme.whiteTextColor}
              >
                {backButton}
                <Scroll borderColor={theme.frameColor} paddingTop={paddingTop}>
                  {(screens ?? [])[index].components?.map((component) => {
                    return (
                      <BaseComponentBuilder
                        key={component.id}
                        component={component}
                      />
                    )
                  })}
                </Scroll>
              </Block>
            )
          }}
        />
      )
      break
    }
    default:
  }

  return (
    <Container style={{ background: theme.whiteColor.color }}>
      <FormElementsSelectorPopupTitle title={title} onClose={onClose} />
      <Block
        id="Content"
        style={{ flex: 1, overflow: 'hidden', height: '100%', display: 'flex' }}
      >
        {children}
      </Block>
      {bottomView}
    </Container>
  )
}

const FormElementsSelectorPopupTitle = ({
  title,
  onClose
}: {
  title?: string
  onClose: () => void
}): JSX.Element => {
  const app = useApp()

  if (title === undefined || title === '') return <></>

  return (
    <Block padding={{ horizontal: spaceStyle.M }} borderBottom mobile>
      <Row
        justify="space-between"
        style={{ flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        <div style={{ width: 24 }} />
        <Heading2 truncate textColor={app.theme.mainTextColor}>
          {title}
        </Heading2>
        <IconButton
          icon={LocalIconType.Close}
          fontSize={fontSize.XXL}
          onClick={onClose}
        />
      </Row>
    </Block>
  )
}

export default memo(FormElementsSelectorPopup)
