import { Block } from '@/components/base/Block'
import { PrimaryButton } from '@/components/base/Button'
import { Icon, LocalIconType } from '@/components/base/Icon'
import { Row } from '@/components/base/Layout'
import { Text3, Text4 } from '@/components/base/Text'
import { useApp } from '@/hooks/useApp'
import useIsMobile from '@/hooks/useMobile'
import { align } from '@/styles/display'
import { fontSize, fontWeight } from '@/styles/font'
import spaceStyle from '@/styles/space'
import {
  V1ViewBottomActionButtonActionType,
  type V1ViewBottomActionButtonsButton
} from 'my-recruiter-app-ts-if'
import styled from 'styled-components'

interface ResetButtonProps {
  color?: string
  disableColor?: string
}

const ResetButton = styled.button<ResetButtonProps>`
  color: ${(props) => props.color ?? '#1F85FD'};
  font-size: ${fontSize.S};
  font-weight: 400;
  border: none;
  background: transparent;
  :disabled {
    color: ${(props) => props.disableColor ?? 'rgba(0, 0, 0, 0.38)'};
  }
  cursor: pointer;
`

interface Props {
  buttons: V1ViewBottomActionButtonsButton[]
  onReset?: () => void
  onSubmit?: () => void
}
export const FormElementsSelectorBottom = ({
  buttons,
  onReset,
  onSubmit
}: Props): JSX.Element => {
  const { theme } = useApp()

  return (
    <Block borderTop padding={{ vertical: spaceStyle.M }}>
      <Row justify={align.CENTER} align={align.CENTER} gutter={22}>
        {buttons.map((button, index) => {
          switch (button.actionType) {
            case V1ViewBottomActionButtonActionType.Ok:
              return (
                <div key={`${button.actionType}_${index}`}>
                  <PrimaryButton
                    padding={{
                      vertical: spaceStyle.M,
                      horizontal: spaceStyle.XL
                    }}
                    width="146px"
                    textColor={theme.whiteTextColor}
                    disabled={onSubmit === undefined}
                    bgColor={
                      onSubmit !== undefined
                        ? theme.primaryMainColor
                        : theme.inactiveColor
                    }
                    onClick={onSubmit}
                  >
                    <Text3
                      margin={{ all: spaceStyle.NONE }}
                      fontWeight={fontWeight.Bold}
                      textColor={theme.whiteTextColor}
                      cursor="pointer"
                    >
                      {button.label}
                    </Text3>
                  </PrimaryButton>
                </div>
              )
            case V1ViewBottomActionButtonActionType.Reset:
              return (
                <ResetButton
                  key={`${button.actionType}_${index}`}
                  color={theme.primaryMainColor.color}
                  disableColor={theme.inactiveColor.color}
                  onClick={onReset}
                  disabled={onReset === undefined}
                >
                  {button.label}
                </ResetButton>
              )

            default:
              break
          }
          return <></>
        })}
      </Row>
    </Block>
  )
}

interface FormElementsSelectorBackProps {
  onClick: () => void
}

export const FormElementsSelectorBack = (
  props: FormElementsSelectorBackProps
): JSX.Element => {
  const isMobile = useIsMobile()
  const { theme } = useApp()

  return (
    <Block padding={{ vertical: spaceStyle.M }} borderBottom={isMobile}>
      <Block
        padding={{ horizontal: isMobile ? spaceStyle.M : spaceStyle.XL }}
        onClick={props.onClick}
        cursor="pointer"
      >
        <Row align={align.CENTER}>
          <Icon
            iconType={LocalIconType.ArrowBackIos}
            iconStyle={{
              fontSize: fontSize.S,
              theme: theme.subTextColor
            }}
          />
          <Text4
            margin={{ vertical: spaceStyle.NONE }}
            textColor={theme.subTextColor}
            cursor="pointer"
          >
            1つ前に戻る
          </Text4>
        </Row>
      </Block>
    </Block>
  )
}
