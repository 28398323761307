import { type V1View, ViewServiceApi } from 'my-recruiter-app-ts-if'
import { config } from './api-config'

export const viewServiceApi = new ViewServiceApi(config)

export const getViewById = async (
  viewId: string
): Promise<V1View | undefined> => {
  return (await viewServiceApi.viewServiceGetView({ id: viewId })).view
}
