import {
  uploadTempFileToS3,
  type UploadTempFileToS3Request
} from '@/lib/aws/s3/uploadTempFileToS3'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'

export const useUploadTempFileToS3Mutation = (): UseMutationResult<
  void,
  unknown,
  UploadTempFileToS3Request,
  unknown
> => {
  const mutation = useMutation({
    mutationKey: ['upload_temp_file_to_s3'],
    mutationFn: uploadTempFileToS3
  })

  return mutation
}
