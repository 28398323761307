import { type FontWeightType } from '@/styles/font'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import { type ReactNode } from 'react'
import styled from 'styled-components'

interface LinkProps {
  to?: string
  color?: V1AppColorTheme
  underline?: boolean
  children?: ReactNode
  target?: React.HTMLAttributeAnchorTarget
  rel?: string
  fontWeight?: FontWeightType
}

interface Props {
  textColor?: V1AppColorTheme
  underline?: boolean
  fontWeight?: FontWeightType
}

const Container = styled.a<Props>`
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight ?? '400'};
  text-decoration: ${(props) =>
    (props.underline ?? false) ? 'underline' : 'none'};
  color: ${(props) => props.textColor?.color ?? 'rgba(31, 133, 253, 1)'};
`

const Link = (props: LinkProps): JSX.Element => {
  return (
    <Container
      textColor={props.color}
      href={props.to}
      underline={props.underline}
      target={props.target}
      rel={props.rel}
      fontWeight={props.fontWeight}
    >
      {props.children}
    </Container>
  )
}

export default Link
