import {
  StorageServiceApi,
  type StorageServiceMoveMessageAttachFilesToPersistenceRequest,
  type StorageServiceMoveTmpResumeFilesToPersistenceRequest,
  type StorageServiceMoveTmpWorkScheduleFilesToPersistenceRequest
} from 'my-recruiter-app-ts-if'
import { config } from './api-config'

export const storageServiceApi = new StorageServiceApi(config)

export const moveTmpResumeFilesToPersistence = async (
  request: StorageServiceMoveTmpResumeFilesToPersistenceRequest
): Promise<object> => {
  const result =
    await storageServiceApi.storageServiceMoveTmpResumeFilesToPersistence(
      request
    )

  return result
}

export const moveTmpWorkScheduleFilesToPersistence = async (
  request: StorageServiceMoveTmpWorkScheduleFilesToPersistenceRequest
): Promise<object> => {
  const result =
    await storageServiceApi.storageServiceMoveTmpWorkScheduleFilesToPersistence(
      request
    )

  return result
}

export const moveTmpMessageFilesToPersistence = async (
  request: StorageServiceMoveMessageAttachFilesToPersistenceRequest
): Promise<object> => {
  const result =
    await storageServiceApi.storageServiceMoveMessageAttachFilesToPersistence(
      request
    )

  return result
}
