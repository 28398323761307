import {
  type V1Component,
  V1ComponentType,
  type V1BasicInfoTableComponent,
  type V1CatchphraseComponent,
  type V1ImagesComponent,
  type V1LabelsComponent,
  type V1TagsComponent,
  type V1ParagraphComponent,
  type V1TitleComponent,
  type V1AdviserComponent,
  type V1SubDescriptionComponent,
  type V1JobPostingDetailSrcInfoComponent
} from 'my-recruiter-app-ts-if'
import { lazy } from 'react'
import { ComponentWrapper } from '../Builder/Component/ComponentWrapper'
const JobPostingBasicInfoContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingBasicInfoContainer'
    )
)
const JobPostingCatchphraseContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingCatchphraseContainer'
    )
)
const JobPostingImagesContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingImagesContainer'
    )
)
const JobPostingLabelsContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingLabelsContainer'
    )
)
const JobPostingTagsContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingTagsContainer'
    )
)
const JobPostingSrcInfoContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingSrcInfoContainer'
    )
)
const JobPostingParagraphContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingParagraphContainer'
    )
)
const JobPostingTitleContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingTitleContainer'
    )
)
const JobPostingAdvisorContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingAdvisorContainer'
    )
)
const JobPostingSubDescriptionContainer = lazy(
  async () =>
    await import(
      '@/feature/JobPosting/containers/JobPostingDetail/JobPostingSubDescriptionContainer'
    )
)

interface ComponentProps {
  component: V1Component
  refetch?: () => void
}

function JobPostingDetailComponentBuilder(props: ComponentProps): JSX.Element {
  // const navigator = useNavigator()
  switch (props.component.type) {
    case V1ComponentType.Title:
      return (
        <ComponentWrapper>
          <JobPostingTitleContainer
            component={props.component.component as V1TitleComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Images:
      return (
        <ComponentWrapper>
          <JobPostingImagesContainer
            component={props.component.component as V1ImagesComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Labels:
      return (
        <ComponentWrapper>
          <JobPostingLabelsContainer
            component={props.component.component as V1LabelsComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Tags:
      return (
        <ComponentWrapper>
          <JobPostingTagsContainer
            component={props.component.component as V1TagsComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.SrcInfo:
      return (
        <ComponentWrapper>
          <JobPostingSrcInfoContainer
            component={
              props.component.component as V1JobPostingDetailSrcInfoComponent
            }
          />
        </ComponentWrapper>
      )
    case V1ComponentType.BasicInfoTable:
      return (
        <ComponentWrapper>
          <JobPostingBasicInfoContainer
            component={props.component.component as V1BasicInfoTableComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Paragraph:
      return (
        <ComponentWrapper>
          <JobPostingParagraphContainer
            component={props.component.component as V1ParagraphComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Catchphrase:
      return (
        <ComponentWrapper>
          <JobPostingCatchphraseContainer
            component={props.component.component as V1CatchphraseComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Adviser:
      return (
        <ComponentWrapper>
          <JobPostingAdvisorContainer
            component={props.component.component as V1AdviserComponent}
          />
        </ComponentWrapper>
      )

    case V1ComponentType.SubDescription:
      return (
        <ComponentWrapper>
          <JobPostingSubDescriptionContainer
            component={props.component.component as V1SubDescriptionComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.JobPostingFavorite:
    default:
      console.log(
        `${props.component.type?.toString() ?? ''} not implement component`
      )
      return <></>
  }
}

export default JobPostingDetailComponentBuilder
