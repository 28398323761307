import { type V1IconType } from 'my-recruiter-app-ts-if'
import styled from 'styled-components'
import { type LocalIconType } from '../base/Icon'
import { type ReactNode } from 'react'

const StyledContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  position: relative;
`

const StyledCompanyImage = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const StyledLinkList = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 0;
  margin-bottom: 24px;
`

const StyledLink = styled.a`
  font-size: 12px;
  color: black;
  line-height: 1.5;
  text-decoration: none;
`

const StyledCopyright = styled.p`
  font-size: 10px;
  color: black;
  line-height: 1.5;
  margin: 0px;
`

export interface Navigation {
  name: string
  icon: V1IconType | LocalIconType
  link: string
}

interface FooterProps {
  company: ReactNode
  footerNavigations: Navigation[]
  footerCopyright: string
}

const Footer = ({
  company,
  footerNavigations,
  footerCopyright
}: FooterProps): JSX.Element => {
  return (
    <StyledContainer>
      <StyledCompanyImage>{company}</StyledCompanyImage>
      <StyledLinkList>
        {footerNavigations.map((footerNavigation, idx) => {
          return (
            <StyledLink
              key={`footer_${idx}`}
              href={footerNavigation.link ?? ''}
              target="_blank"
            >
              {footerNavigation.name}
            </StyledLink>
          )
        })}
      </StyledLinkList>
      <StyledCopyright>{footerCopyright}</StyledCopyright>
    </StyledContainer>
  )
}

export default Footer
