import { memo, useState } from 'react'
import {
  type V1FormElementState,
  type V1FormElementContentLabelAndState,
  type V1FormElementUiSelectSearch,
  type V1FormElementSelectSearchType
} from 'my-recruiter-app-ts-if'
import { Row } from '@/components/base/Layout'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { SubButton } from '../base/Button'
import { align } from '@/styles/display'
import { buttonSize } from '../base/Button/Base'
import { Modal } from '../base/Modal'
import BasePopUp from '../base/PopUp/BasePopUp'
import { Text3 } from '../base/Text'
import { showFormValue } from './formText'

const gap = 12

export interface FormElementSearchFieldProps {
  isEdit: boolean
  name: string
  selectSearchUiIFM: V1FormElementUiSelectSearch
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
  handleStationTrainLinesModal?: () => void
  onClickSearchField?: (
    searchType: V1FormElementSelectSearchType,
    setNewOptions: (newOptions: V1FormElementContentLabelAndState[]) => void
  ) => void
}

const FormElementSearchField = ({
  isEdit,
  name,
  selectSearchUiIFM,
  formElementState,
  onChange,
  handleStationTrainLinesModal,
  onClickSearchField
}: FormElementSearchFieldProps): JSX.Element => {
  const textState = ''

  if (!isEdit) {
    return (
      <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
        {showFormValue(textState)}
      </Text3>
    )
  }

  const [showModal, setShowModal] = useState(false)
  const [options, setOptions] = useState<V1FormElementContentLabelAndState[]>(
    []
  )

  const onClickSearch = (): void => {
    const searchType = selectSearchUiIFM.searchType
    if (searchType === undefined || onClickSearchField === undefined) {
      return
    }
    const setNewOptions = (
      newOptions: V1FormElementContentLabelAndState[]
    ): void => {
      setOptions(newOptions)
    }
    onClickSearchField(searchType, setNewOptions)
    setShowModal(true)
  }

  const searchButtonWidth = 72

  return (
    <>
      <Row gutter={gap} align={align.CENTER}>
        <SubButton
          maxWidth={`${searchButtonWidth}px`}
          height={buttonSize.S}
          onClick={() => {
            if (handleStationTrainLinesModal != null) {
              handleStationTrainLinesModal()
            }
          }}
          disableClickAction={onClickSearch === undefined}
          fontSize={fontSize.S}
          padding={{
            vertical: spaceStyle.M,
            horizontal: spaceStyle.XL
          }}
        >
          選択
        </SubButton>
      </Row>
      <PopUp
        showModal={showModal}
        options={options}
        onCloseModal={() => {
          setShowModal(false)
        }}
        onClickOption={(option: V1FormElementContentLabelAndState) => {
          setShowModal(false)
        }}
      />
    </>
  )
}

export default memo(FormElementSearchField)

interface PopUpProps {
  showModal: boolean
  options: V1FormElementContentLabelAndState[]
  onCloseModal: () => void
  onClickOption: (option: V1FormElementContentLabelAndState) => void
}

const PopUp = ({
  showModal,
  options,
  onCloseModal,
  onClickOption
}: PopUpProps): JSX.Element => {
  return (
    <Modal isOpen={showModal}>
      <BasePopUp
        inactiveButton={{
          name: '閉じる',
          onClickButton: onCloseModal
        }}
        onClickClose={onCloseModal}
      >
        <>
          <p>(仮デザインです)</p>
          {options.map((option) => {
            return (
              <p
                style={{
                  cursor: 'pointer'
                }}
                key={option.state?.uint32 ?? 0}
                onClick={() => {
                  onClickOption(option)
                }}
              >
                {option.label}
              </p>
            )
          })}
        </>
      </BasePopUp>
    </Modal>
  )
}
