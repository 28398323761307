import { memo } from 'react'

import { Block } from '@/components/base/Block'
import { InputText } from '@/components/base/Form/InputText'
import Validator from '@/components/base/Form/InputText/InputValidate'
import { Text3 } from '@/components/base/Text'
import { align, display } from '@/styles/display'
import { fontSize, fontWeight } from '@/styles/font'
import spaceStyle from '@/styles/space'
import textAlign from '@/styles/text_align'
import {
  type V1FormElementContentState,
  type V1FormElementState,
  type V1FormElementUiTextField
} from 'my-recruiter-app-ts-if'
import { showFormValue } from '../formText'

export interface FormElementTextFieldProps {
  label?: string
  isEdit?: boolean
  name: string
  textFieldUiIFM: V1FormElementUiTextField
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementTextFieldEmail = ({
  label,
  isEdit = true,
  textFieldUiIFM,
  formElementState,
  elemenErrorMessage,
  onChange,
  name
}: FormElementTextFieldProps): JSX.Element => {
  const stateValue =
    formElementState.contentStates?.at(0)?.text ??
    formElementState.contentStates?.at(0)?.uint32 ??
    ''

  if (!isEdit) {
    return (
      <Block display={display.FLEX} alignItems={align.CENTER}>
        <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
          {showFormValue(stateValue)}
        </Text3>
      </Block>
    )
  }

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT') {
    const onChangeText = (value: string): void => {
      onChange({
        ...formElementState,
        contentStates: [
          {
            text: value
          } satisfies V1FormElementContentState
        ]
      })
    }

    const textFieldLabel = (
      <Text3
        textAlign={textAlign.LEFT}
        fontWeight={fontWeight.Bold}
        margin={{ all: spaceStyle.NONE }}
      >
        {label}
      </Text3>
    )

    return (
      <>
        {textFieldLabel}
        <InputText
          maxWidth="100%"
          value={stateValue}
          name={name}
          onChange={(value) => {
            onChangeText(value)
          }}
          placeholder={textFieldUiIFM?.placeholder}
          placeholderFontSize={
            formElementState.key === 'keyword_text' ? fontSize.S : undefined
          }
          errorMessage={elemenErrorMessage}
          type={'email'}
          onValidateError={(text) => {
            const valid = Validator.isEmail(text)
            return valid
              ? undefined
              : '正しいメールアドレスの形式で入力してください'
          }}
        />
      </>
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementTextFieldEmail)
