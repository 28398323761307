import {
  type V1FormElement,
  type V1FormElementState,
  V1FormElementUiType,
  type V1FormElementUiCheckBoxList,
  type V1FormElementUiRadioList,
  type V1FormElementUiSelectBox,
  type V1FormElementUiTextField,
  type V1FormElementUiDateSelectBox,
  type V1FormElementUiAreaSelect,
  type V1FormElementUiOccupationCategorySelect,
  type V1FormElementContentState,
  type V1FormElementUiZipCode,
  type V1FormElementUiSelectSearch,
  V1FormElementValidateType
} from 'my-recruiter-app-ts-if'

import { memo } from 'react'
import FormElementCheckboxList from './FormElementCheckBoxList'
import FormElementTextField from './TextField/FormElementTextField'
import FormLabel from './FormLabel'
import { Block } from '@/components/base/Block'
import { FormElementSelectBox } from './SelectBox/FormElementSelectBox'
import FormElementRadioList from './Radio/FormElementRadioList'
import { type FormElementStyle } from './FormElements'
import FormElementTextAreaField from './TextField/FormElementTextAreaField'
import { generateUUID } from '../Form/uitl'
import { FormElementDateSelectBox } from './SelectBox/FormElementDateSelectBox'
import FormElementCheckBoxListSimple from './FormElementCheckBoxListSimple'
import FormElementAreaSelect from './FormElementAreaSelect'
import FormElementOccupationCategoryRadioList from './FormElementOccupationCategoryRadioList'
import FormElementZipCode from './FormElementZipCode'
import FormElementSearchField from './FormElementSearchField'
import FormElementSelectorPopupButtons from './FormElementsSelectorPopupButtons/FormElementSelectorPopupButtons'
import { FormELementTextAndSelectorPopup } from './FormELementTextAndSelectorPopup'
import FormElementUserIdField from './FormElementUserIdField'
import FormElementTextFieldEmail from './TextField/FormElementTextFieldEmail'
import FormElementInlineTextAreaField from './TextField/FormElementInlineTextAreaField'
import FormElementInlineTextField from './TextField/FormElementInlineTextField'

interface Props {
  isEdit?: boolean
  hiddenLabel?: boolean
  formElement: V1FormElement
  formElementState: V1FormElementState
  formElementContents?: V1FormElementContentState[]
  formElementStyle?: FormElementStyle
  elemenErrorMessage?: string
  searchAddressByZipCode?: (
    formElementUiZipCode: V1FormElementUiZipCode,
    formElementState: V1FormElementState,
    address: string
  ) => Promise<void>
  handleStationTrainLinesModal?: () => void
  onChange: (newFormElementState: V1FormElementState) => void
  onSelectElement?: (uiType: V1FormElementUiType, element: any) => void
}

const FormElement = ({
  isEdit = true,
  hiddenLabel = false,
  formElement,
  formElementState,
  formElementContents,
  formElementStyle,
  elemenErrorMessage,
  searchAddressByZipCode,
  handleStationTrainLinesModal,
  onChange,
  onSelectElement
}: Props): JSX.Element => {
  const buildChild = (): JSX.Element => {
    switch (formElement?.uiType) {
      case V1FormElementUiType.ZipCode: {
        return (
          <FormElementZipCode
            isEdit={isEdit}
            zipCodeUiIFM={formElement.ui as V1FormElementUiZipCode}
            name={`${formElement.state?.key ?? ''}_${generateUUID()}`}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            searchAddressByZipCode={searchAddressByZipCode}
            onChange={onChange}
          />
        )
      }
      case V1FormElementUiType.CheckBoxList: {
        return (
          <FormElementCheckboxList
            checkBoxListUiIFM={formElement.ui as V1FormElementUiCheckBoxList}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
          />
        )
      }
      case V1FormElementUiType.SimpleCheckBoxList: {
        return (
          <FormElementCheckBoxListSimple
            isEdit={isEdit}
            checkBoxListSimpleUiIFM={
              formElement.ui as V1FormElementUiCheckBoxList
            }
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
          />
        )
      }
      case V1FormElementUiType.RadioList: {
        return (
          <FormElementRadioList
            isEdit={isEdit}
            radioListUiIFM={formElement.ui as V1FormElementUiRadioList}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
          />
        )
      }
      case V1FormElementUiType.SelectBox:
        return (
          <FormElementSelectBox
            isEdit={isEdit}
            selectBoxUiIFM={formElement.ui as V1FormElementUiSelectBox}
            formElementState={formElementState}
            formElementContents={formElementContents}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
            style={formElementStyle?.selectBox}
          />
        )
      case V1FormElementUiType.TextField:
        if (formElement?.validateType === V1FormElementValidateType.Email) {
          return (
            <FormElementTextFieldEmail
              isEdit={isEdit}
              name={`${formElement.state?.key ?? ''}_${generateUUID()}`}
              textFieldUiIFM={formElement.ui as V1FormElementUiTextField}
              formElementState={formElementState}
              elemenErrorMessage={elemenErrorMessage}
              onChange={onChange}
            />
          )
        } else {
          return (
            <FormElementTextField
              isEdit={isEdit}
              name={`${formElement.state?.key ?? ''}_${generateUUID()}`}
              textFieldUiIFM={formElement.ui as V1FormElementUiTextField}
              formElementState={formElementState}
              elemenErrorMessage={elemenErrorMessage}
              onChange={onChange}
            />
          )
        }
      case V1FormElementUiType.TextAreaField:
        return (
          <FormElementTextAreaField
            isEdit={isEdit}
            textFieldUiIFM={formElement.ui as V1FormElementUiTextField}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
          />
        )
      case V1FormElementUiType.DateSelectBox:
        return (
          <FormElementDateSelectBox
            isEdit={isEdit}
            uiIFM={formElement.ui as V1FormElementUiDateSelectBox}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
            style={formElementStyle?.selectBox}
          />
        )
      case V1FormElementUiType.SelectSearch:
        return (
          <FormElementSearchField
            isEdit={isEdit}
            name={''}
            selectSearchUiIFM={formElement.ui as V1FormElementUiSelectSearch}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            handleStationTrainLinesModal={handleStationTrainLinesModal}
            onChange={(element) => {
              if (onSelectElement !== undefined) {
                onSelectElement(V1FormElementUiType.AreaSelect, element)
              }
            }}
          />
        )
      case V1FormElementUiType.AreaSelect:
        return (
          <FormElementAreaSelect
            areaSelectUiIFM={formElement.ui as V1FormElementUiAreaSelect}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onClick={(element) => {
              if (onSelectElement !== undefined) {
                onSelectElement(V1FormElementUiType.AreaSelect, element)
              }
            }}
          />
        )
      case V1FormElementUiType.OccupationCategoryCheckboxList:
        return (
          <FormElementOccupationCategoryRadioList
            occupationCategoryUiIFM={
              formElement.ui as V1FormElementUiOccupationCategorySelect
            }
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onClick={(element) => {
              if (onSelectElement !== undefined) {
                onSelectElement(
                  V1FormElementUiType.OccupationCategoryCheckboxList,
                  element
                )
              }
            }}
          />
        )
      case V1FormElementUiType.SelectorPopupButtons:
        return (
          <FormElementSelectorPopupButtons
            form={formElement}
            onChange={onChange}
          />
        )
      case V1FormElementUiType.TextAndSelectorPopup:
        return (
          <FormELementTextAndSelectorPopup
            isEdit={isEdit}
            form={formElement}
            onChange={onChange}
            formElementState={formElementState}
          />
        )
      case V1FormElementUiType.UserId:
        return (
          <FormElementUserIdField
            isEdit={isEdit}
            formElementState={formElementState}
          />
        )

      case V1FormElementUiType.InlineTextField:
        return (
          <FormElementInlineTextField
            isEdit={isEdit}
            name={`${formElement.state?.key ?? ''}_${generateUUID()}`}
            textFieldUiIFM={formElement.ui as V1FormElementUiTextField}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
            label={formElement.label}
          />
        )

      case V1FormElementUiType.InlineTextAreaField:
        return (
          <FormElementInlineTextAreaField
            isEdit={isEdit}
            textFieldUiIFM={formElement.ui as V1FormElementUiTextField}
            formElementState={formElementState}
            elemenErrorMessage={elemenErrorMessage}
            onChange={onChange}
            label={formElement.label}
          />
        )
      default:
        console.log(`${formElement?.uiType?.toString() ?? ''} not implemented`)
        return <></>
    }
  }

  return (
    <Block>
      {!hiddenLabel && formElement?.label !== undefined && (
        <FormLabel>{formElement.label}</FormLabel>
      )}
      {buildChild()}
    </Block>
  )
}

export default memo(FormElement)
