import { Block } from '@/components/base/Block'
import { SubButton } from '@/components/base/Button'
import { IconPosition, buttonSize } from '@/components/base/Button/Base'
import { Icon, LocalIconType } from '@/components/base/Icon'
import { Text4, Text5 } from '@/components/base/Text'
import { ComposeMessageContext } from '@/feature/Builder/Component/ComposeMessageWapper'
import { useApp } from '@/hooks/useApp'
import { useFileUploadMessage } from '@/hooks/useFileUploadMessage'
import AppAssets from '@/styles/app_assets'
import borderRadius from '@/styles/border_radius'
import { cursor } from '@/styles/cursor'
import { align, display } from '@/styles/display'
import { fontSize, fontWeight } from '@/styles/font'
import spaceStyle from '@/styles/space'
import textAlign from '@/styles/text_align'
import { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from '../base/Layout'
import { useGetPresingedURLsForPut } from '@/lib/aws/s3/getPresignedUrl'
import useIsMobile from '@/hooks/useMobile'

export interface FileUploadType extends File {
  url?: string
}

const MesageFileUpload = (): JSX.Element => {
  const context = useContext(ComposeMessageContext)

  const { isEdit, listFile, setListFile } = context
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const tmpFileUploadUrlQueries = useGetPresingedURLsForPut(selectedFiles)

  const app = useApp()
  const isMobile = useIsMobile()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { invokeUpload: invokeFileUpload } = useFileUploadMessage(
    tmpFileUploadUrlQueries,
    selectedFiles
  )

  const isAllSuccessFile =
    tmpFileUploadUrlQueries.findIndex((item) => !item.isSuccess) < 0

  useEffect(() => {
    if (selectedFiles.length > 0 && isAllSuccessFile) {
      void (async (): Promise<void> => {
        const listFileName = await invokeFileUpload()
        setListFile(listFileName)
      })()
    }
  }, [isAllSuccessFile, selectedFiles])

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const uploadFiles = event.target.files
    if (uploadFiles == null) {
      return
    }
    const files: FileUploadType[] = [...selectedFiles, ...uploadFiles]
    setSelectedFiles(files)
  }

  const openFileSelector = (): void => {
    fileInputRef.current?.click()
  }

  const onClickRemoveFile = (index: number): void => {
    selectedFiles.splice(index, 1)
    setSelectedFiles([...selectedFiles])
  }

  return (
    <Block
      padding={{ vertical: spaceStyle.L }}
      margin={{
        left: isEdit
          ? spaceStyle.XXXXXL
          : isMobile
            ? spaceStyle.M
            : spaceStyle.NONE,
        right: isMobile
          ? spaceStyle.M
          : isEdit
            ? spaceStyle.XXXXXL
            : spaceStyle.NONE
      }}
      borderTop={isEdit || (!isEdit && listFile.length > 0)}
    >
      {isEdit && (
        <>
          <SubButton
            fontWeight={fontWeight.Bold}
            height={buttonSize.M}
            textColor={app.theme.subTextColor}
            iconProps={{
              iconType: LocalIconType.Attachment,
              iconStyle: {
                theme: app.theme.subTextColor
              },
              position: IconPosition.left
            }}
            onClick={openFileSelector}
          >
            ファイルを選択
          </SubButton>
          <input
            hidden
            type="file"
            onChange={onFileChange}
            ref={fileInputRef}
            multiple={true}
          />
          <Text5 textAlign={align.LEFT} margin={{ vertical: spaceStyle.M }}>
            ※合計4MB以内のファイルが添付できます
          </Text5>
        </>
      )}
      {selectedFiles.map((file, index) => (
        <Row key={file.name} margin={{ bottom: spaceStyle.S }}>
          <Block
            display={display.FLEX}
            justifyContent={align.SPACE_BETWEEN}
            border
            borderRadius={{ all: borderRadius.MAX }}
            padding={{ horizontal: spaceStyle.M, vertical: spaceStyle.S }}
            style={{ gap: spaceStyle.XXXXXL }}
          >
            <Col justify={align.CENTER}>
              <Block display={display.FLEX} justifyContent={align.CENTER}>
                <img src={AppAssets.icDescription} />
                <Text4
                  textAlign={textAlign.LEFT}
                  textColor={app.theme.primaryMainColor}
                  margin={{ all: spaceStyle.NONE }}
                >
                  {file.name}
                </Text4>
              </Block>
            </Col>
            <Col justify={align.CENTER}>
              <Icon
                iconType={LocalIconType.Close}
                iconStyle={{
                  fontSize: fontSize.M,
                  theme: app.theme.disabledColor,
                  cursor: cursor.POINTER
                }}
                onClick={() => {
                  onClickRemoveFile(index)
                }}
              />
            </Col>
          </Block>
        </Row>
      ))}
    </Block>
  )
}

export default MesageFileUpload
