const prefixPath = window.location.origin + '/assets/'
const AppAssets = {
  icArrowForward: prefixPath + 'ic_arrow_forward.svg',
  icEye: prefixPath + 'ic_eye_visible.svg',
  icEyeInvisible: prefixPath + 'ic_eys_invisible.svg',
  icSearch: prefixPath + 'ic_search.svg',
  icQuote: prefixPath + 'ic_quote.svg',
  icArrowPrev: prefixPath + 'ic_arrow_prev.svg',
  icArrowNext: prefixPath + 'ic_arrow_next.svg',
  logoKango: prefixPath + 'logo_kango.jpg',
  logoHoiko: prefixPath + 'logo_hoiko.jpg',
  logoPharma: prefixPath + 'logo_pharma.jpg',
  logoKaigoshoku: prefixPath + 'logo_kaigoshoku.jpg',
  logoCoMedical: prefixPath + 'logo_co-medical.jpg',
  icCompanyProfile: prefixPath + 'company_profile.svg',
  icDescription: prefixPath + 'ic_description.svg'
}

export default AppAssets
