import { Block } from '@/components/base/Block'
import spaceStyle from '@/styles/space'
import { size } from '@/styles/device'
import { type ReactNode } from 'react'

interface ComponentWrapperProps {
  children: ReactNode
}

export function ComponentWrapper({
  children
}: ComponentWrapperProps): JSX.Element {
  return (
    <Block
      margin={{ vertical: spaceStyle.M, horizontal: spaceStyle.AUTO }}
      maxWidth={size.laptop}
    >
      {children}
    </Block>
  )
}
