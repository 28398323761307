import { fontSize, fontWeight } from '@/styles/font'
import BaseText from './BaseText'
import { type TextProps } from './props'

const Text5 = (props: TextProps): JSX.Element => {
  return (
    <BaseText
      fontSize={fontSize.XXS}
      fontWeight={fontWeight.Regular}
      {...props}
    >
      {props.children}
    </BaseText>
  )
}

export default Text5
