import { memo } from 'react'

import {
  type V1FormElementState,
  type V1FormElementUiAreaSelect,
  type V1AreaSelectElementContent,
  V1FormElementStateType
} from 'my-recruiter-app-ts-if'
import { Row } from '../base/Layout'
import { commonBorder } from '@/styles/border'
import { useApp } from '@/hooks/useApp'
import borderRadius from '@/styles/border_radius'
import spaceStyle from '@/styles/space'
import { fontSize, fontWeight } from '@/styles/font'
import { AccentButton } from '../base/Button'

export interface FormElementAreaSelectProps {
  areaSelectUiIFM: V1FormElementUiAreaSelect
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onClick?: (newElementState: V1AreaSelectElementContent) => void
}

const FormElementAreaSelect = ({
  areaSelectUiIFM,
  formElementState,
  onClick
}: FormElementAreaSelectProps): JSX.Element => {
  if (formElementState.type === V1FormElementStateType.Uint32List) {
    const app = useApp()

    return (
      <>
        <Row gutter={8}>
          {areaSelectUiIFM.contents?.map((e) => {
            // TODO entryPointAreaType
            // リリース優先のため一旦コメントアウト
            // 25行目のタスク
            // https://docs.google.com/spreadsheets/d/14CKkqjAl5atf4IVdmbzDHbweahL0JH0MWawaa78mdwU/edit#gid=0
            if (e.entryPointAreaType === 'ENTRY_POINT_AREA_TYPE_TRAIN') {
              return <></>
            }
            return (
              <AccentButton
                // width="calc((100% - 8px) / 2)"
                width="100%"
                style={{
                  boxShadow: 'none',
                  justifyContent: 'left',
                  boxSizing: 'border-box',
                  flex: 'none'
                }}
                padding={{ vertical: spaceStyle.L, horizontal: spaceStyle.M }}
                key={e.entryPointAreaType}
                iconProps={{
                  iconType: e.iconType ?? 'ICON_TYPE_UNSPECIFIED',
                  iconStyle: { theme: app.theme.primaryMainColor }
                }}
                onClick={(event) => {
                  event.preventDefault()
                  if (onClick != null) onClick(e)
                }}
                border={{ ...commonBorder, color: app.theme.frameColor }}
                borderRadius={borderRadius.M}
                fontSize={fontSize.S}
                fontWeight={fontWeight.Regular}
                textColor={app.theme.mainTextColor}
              >
                {e.name ?? ''}
              </AccentButton>
            )
          })}
        </Row>
      </>
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementAreaSelect)
