import {
  FormElementsSelectorBack,
  FormElementsSelectorBottom
} from '@/components/FormElement/FormElementsSelectorPopupButtons/FormElementsSelectorPopup/FormElementsSelectorBottom'
import {
  type V1ViewBottomActionButtons,
  type V1ViewBottom
} from 'my-recruiter-app-ts-if'
import { onBack, onResetSelected } from './fn'
import { FormElementsSelectorContext } from './context'
import { useContext } from 'react'

interface Props {
  view?: V1ViewBottom
  onSubmit?: () => void
}
export const FormElementsSelectorBottomContainer = ({
  view,
  onSubmit
}: Props): JSX.Element => {
  const { buttons } = view?.data as V1ViewBottomActionButtons

  if ((buttons ?? []).length === 0) return <></>

  const { state, dispatch } = useContext(FormElementsSelectorContext)

  return (
    <FormElementsSelectorBottom
      onReset={
        state.values.length > 0
          ? () => {
              onResetSelected(dispatch)
            }
          : undefined
      }
      onSubmit={state.values.length > 0 ? onSubmit : undefined}
      buttons={buttons ?? []}
    />
  )
}

export const FormElementsSelectorBackContainer = (): JSX.Element => {
  const { state, dispatch } = useContext(FormElementsSelectorContext)

  if (state.items.length < 2) return <></>

  return (
    <FormElementsSelectorBack
      onClick={() => {
        onBack(dispatch)
      }}
    />
  )
}
