import { memo, useEffect, useState } from 'react'

import { Block } from '@/components/base/Block'
import { Row } from '@/components/base/Layout'
import type OptionType from '../OptionType'
import { Radio } from '@/components/base/Form/Radio'
import { Text3 } from '@/components/base/Text'
import spaceStyle from '@/styles/space'
import {
  type V1FormElementUiRadioList,
  type V1FormElementState,
  type V1FormElementContentState
} from 'my-recruiter-app-ts-if'
import { align } from '@/styles/display'
import InputErrorLabel from '@/components/base/Form/InputErrorLabel'

interface GenericFormElementRadioListOption<T extends OptionType> {
  value: T
  label: string
  width?: string
}

interface GenericFormElementRadioListProps<T extends OptionType> {
  options: Array<GenericFormElementRadioListOption<T>>
  name: string
  initialSelectedValue: T
  hasError: boolean
  onChange: (newSelectedValues: T, label: string) => void
}

function GenericFormElementRadioList<T extends OptionType>({
  options,
  name,
  initialSelectedValue,
  hasError,
  onChange
}: GenericFormElementRadioListProps<T>): JSX.Element {
  const [selectedValueState, setSelectedValueState] =
    useState<T>(initialSelectedValue)

  const gutter = 28

  return (
    <Row gutter={gutter}>
      {options.map((option) => {
        return (
          <Block key={option.label}>
            <Radio
              checked={selectedValueState === option.value}
              option={{
                name,
                value: option.value as string
              }}
              hasError={hasError}
              onChange={(event) => {
                let value: T
                if (typeof initialSelectedValue === 'number') {
                  value = Number(event.target.value) as T
                } else {
                  value = event.target.value as T
                }
                setSelectedValueState(value)
                onChange(value, option.label)
              }}
            >
              <Text3 margin={{ all: spaceStyle.NONE }}>{option.label}</Text3>
            </Radio>
          </Block>
        )
      })}
    </Row>
  )
}

export interface FormElementRadioListProps {
  isEdit: boolean
  radioListUiIFM: V1FormElementUiRadioList
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementRadioList = ({
  isEdit,
  radioListUiIFM,
  formElementState,
  elemenErrorMessage,
  onChange
}: FormElementRadioListProps): JSX.Element => {
  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32') {
    const options: Array<GenericFormElementRadioListOption<number>> =
      radioListUiIFM.contents?.map((e) => {
        return {
          label: e.label ?? '',
          value: e.state?.uint32 ?? 0
        } satisfies GenericFormElementRadioListOption<number>
      }) ?? []

    const selectedValue: number =
      formElementState.contentStates?.at(0)?.uint32 ?? options[0]?.value
    const label: string = formElementState.contentStates?.at(0)?.label ?? ''

    useEffect(() => {
      if (selectedValue !== 0) {
        onChange({
          ...formElementState,
          contentStates: [
            {
              uint32: selectedValue,
              label
            } satisfies V1FormElementContentState
          ]
        })
      }
    }, [])

    return (
      <>
        {!isEdit ? (
          <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
            {formElementState.contentStates?.at(0)?.label ?? ''}
          </Text3>
        ) : (
          <>
            <GenericFormElementRadioList
              options={options}
              name={formElementState.key ?? ''}
              initialSelectedValue={selectedValue}
              hasError={elemenErrorMessage !== undefined}
              onChange={(selectedValue, label) => {
                onChange({
                  ...formElementState,
                  contentStates: [
                    {
                      uint32: selectedValue,
                      label
                    } satisfies V1FormElementContentState
                  ]
                })
              }}
            />
            {elemenErrorMessage !== undefined && (
              <InputErrorLabel message={elemenErrorMessage} />
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementRadioList)
