import { memo } from 'react'
import styled from 'styled-components'
import { fontSize } from '@/styles/font'

const StyledInputErrorLabel = styled.div`
  paddingbottom: 8;
  color: theme.mainTextColor.color ?? 'black';
  text-align: left;
  color: red};
`

interface InputErrorLabelProps {
  message?: string
}

const InputErrorLabel = (props: InputErrorLabelProps): JSX.Element => {
  return props.message !== undefined ? (
    <StyledInputErrorLabel>
      <span style={{ fontSize: fontSize.XXS }}>{props.message}</span>
    </StyledInputErrorLabel>
  ) : (
    <div />
  )
}

export default memo(InputErrorLabel)
