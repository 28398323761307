import { useApp } from '@/hooks/useApp'
import spaceStyle from '@/styles/space'
import { Block } from '@/components/base/Block'
import { PrimaryButton, SubButton } from '@/components/base/Button'
import { buttonSize } from '@/components/base/Button/Base'
import { fontWeight } from '@/styles/font'
import { ComposeMessageContext } from '@/feature/Builder/Component/ComposeMessageWapper'
import { display, align } from '@/styles/display'
import { useContext } from 'react'
import { useNavigator } from '@/hooks/useNavigator'

interface NewMessageBottomBarComponentProps {
  handleSaveDraft: () => void
  handleSendMessage: () => void
}

export const NewMessageBottomBar = ({
  handleSaveDraft,
  handleSendMessage
}: NewMessageBottomBarComponentProps): JSX.Element => {
  const app = useApp()
  const nav = useNavigator()

  const context = useContext(ComposeMessageContext)

  const { isEdit, setIsEdit } = context

  return (
    <Block
      borderTop
      borderBottom
      display={display.FLEX}
      justifyContent={align.CENTER}
      padding={{
        vertical: spaceStyle.M
      }}
      backgroundColor={app.theme.whiteTextColor}
      width={'100%'}
      margin={{ horizontal: spaceStyle.NONE }}
      style={{
        gap: spaceStyle.M,
        position: 'sticky',
        bottom: 0,
        left: 0,
        zIndex: 10
      }}
    >
      {isEdit ? (
        <>
          <PrimaryButton
            maxWidth="120px"
            height={buttonSize.M}
            onClick={() => {
              setIsEdit(false)
            }}
          >
            送信確認
          </PrimaryButton>
          <SubButton
            maxWidth="120px"
            fontWeight={fontWeight.Bold}
            height={buttonSize.M}
            textColor={app.theme.subTextColor}
            onClick={handleSaveDraft}
          >
            下書き保存
          </SubButton>
          <SubButton
            maxWidth="120px"
            fontWeight={fontWeight.Bold}
            height={buttonSize.M}
            textColor={app.theme.subTextColor}
            onClick={(): void => {
              void nav?.goBack()
            }}
          >
            クリア
          </SubButton>
        </>
      ) : (
        <>
          <PrimaryButton
            maxWidth="120px"
            height={buttonSize.M}
            onClick={handleSendMessage}
          >
            送信
          </PrimaryButton>
          <SubButton
            maxWidth="120px"
            fontWeight={fontWeight.Bold}
            height={buttonSize.M}
            textColor={app.theme.subTextColor}
            onClick={() => {
              setIsEdit(true)
            }}
          >
            キャンセル
          </SubButton>
        </>
      )}
    </Block>
  )
}
