import { memo } from 'react'

import {
  type V1FormElementState,
  type V1FormElementUiTextField,
  type V1FormElementContentState
} from 'my-recruiter-app-ts-if'
import { InputText } from '@/components/base/Form/InputText'
import { Text3 } from '@/components/base/Text'
import { align, display } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { showFormValue } from '../formText'
import textAlign from '@/styles/text_align'
import { fontSize, fontWeight } from '@/styles/font'
import { Block } from '@/components/base/Block'
import AppAssets from '@/styles/app_assets'

export interface FormElementTextFieldProps {
  label?: string
  isEdit?: boolean
  name: string
  textFieldUiIFM: V1FormElementUiTextField
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementTextField = ({
  label,
  isEdit = true,
  textFieldUiIFM,
  formElementState,
  elemenErrorMessage,
  onChange,
  name
}: FormElementTextFieldProps): JSX.Element => {
  const stateValue =
    formElementState.contentStates?.at(0)?.text ??
    formElementState.contentStates?.at(0)?.uint32 ??
    ''
  const unit = textFieldUiIFM.unit

  if (!isEdit) {
    return (
      <Block display={display.FLEX} alignItems={align.CENTER}>
        <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
          {showFormValue(stateValue)}
        </Text3>
        {unit !== undefined && <Text3>{unit}</Text3>}
      </Block>
    )
  }

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT') {
    const onChangeText = (value: string): void => {
      onChange({
        ...formElementState,
        contentStates: [
          {
            text: value
          } satisfies V1FormElementContentState
        ]
      })
    }

    const textFieldLabel = (
      <Text3
        textAlign={textAlign.LEFT}
        fontWeight={fontWeight.Bold}
        margin={{ all: spaceStyle.NONE }}
      >
        {label}
      </Text3>
    )

    return (
      <>
        {textFieldLabel}
        <InputText
          maxWidth="100%"
          type={'text'}
          value={stateValue}
          name={name}
          onChange={(value) => {
            onChangeText(value)
          }}
          placeholder={textFieldUiIFM?.placeholder}
          prefixIcon={
            formElementState.key === 'keyword_text'
              ? AppAssets.icSearch
              : undefined
          }
          placeholderFontSize={
            formElementState.key === 'keyword_text' ? fontSize.S : undefined
          }
          unit={unit}
          errorMessage={elemenErrorMessage}
        />
      </>
    )
  }

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32') {
    const onChangeText = (value: number): void => {
      onChange({
        ...formElementState,
        contentStates: [
          {
            uint32: value
          } satisfies V1FormElementContentState
        ]
      })
    }
    return (
      <InputText
        maxWidth="100%"
        type={'number'}
        value={stateValue}
        name={name}
        onChange={(value) => {
          onChangeText(Number(value))
        }}
        placeholder={textFieldUiIFM?.placeholder}
        unit={unit}
      />
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementTextField)
