import { memo } from 'react'

import {
  type V1FormElementState,
  type V1FormElementUiTextField,
  type V1FormElementContentState
} from 'my-recruiter-app-ts-if'
import {
  InputTextArea,
  type StyledTextareaProps
} from '@/components/base/Form/InputTextArea'
import { Text3 } from '@/components/base/Text'
import { align } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { showFormValue } from '../formText'

export interface FormElementTextAreaFieldProps extends StyledTextareaProps {
  isEdit: boolean
  textFieldUiIFM: V1FormElementUiTextField
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementTextAreaField = ({
  height,
  isEdit,
  textFieldUiIFM,
  elemenErrorMessage,
  formElementState,
  onChange
}: FormElementTextAreaFieldProps): JSX.Element => {
  const textState = formElementState.contentStates?.at(0)?.text ?? ''

  if (!isEdit) {
    return (
      <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
        {showFormValue(textState)}
      </Text3>
    )
  }

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT') {
    const onChangeText = (value: string): void => {
      onChange({
        ...formElementState,
        type: formElementState.type,
        contentStates: [
          {
            text: value
          } satisfies V1FormElementContentState
        ]
      })
    }

    return (
      <InputTextArea
        height={height ?? '172px'}
        value={textState}
        onChange={(value) => {
          onChangeText(value)
        }}
        placeholder={textFieldUiIFM?.placeholder}
        errorMessage={elemenErrorMessage}
      />
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementTextAreaField)
