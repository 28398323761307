import {
  V1ViewLayoutType,
  type V1View,
  type V1TabBarView,
  type V1SingleView,
  type V1Screen,
  type V1ViewTabBar
} from 'my-recruiter-app-ts-if'
import type React from 'react'
import SingleView from './SingleView'
import { TabBarView } from './TabView'
import { memo } from 'react'

interface ViewProps {
  children?: React.ReactNode
  view?: V1View
  screenBuilder?: (screen: V1Screen, tabBar?: V1ViewTabBar) => JSX.Element[]
  defaultIndex?: number
  customListTab?: React.CSSProperties
}

const View = ({
  children,
  view,
  screenBuilder,
  defaultIndex,
  customListTab
}: ViewProps): React.ReactElement => {
  switch (view?.layoutType) {
    case V1ViewLayoutType.SingleView:
      return (
        <SingleView
          view={view?.data as V1SingleView}
          screenBuilder={screenBuilder}
        >
          {children}
        </SingleView>
      )
    case V1ViewLayoutType.TabBarView:
      return (
        <TabBarView
          view={view?.data as V1TabBarView}
          defaultIndex={defaultIndex}
          screenBuilder={screenBuilder}
          customListTab={customListTab}
        />
      )
    default:
      return <>{children}</>
  }
}

export default memo(View)
