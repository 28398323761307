import useGlobalStyles from '@/styles/app_global_styles'
import useStyles from './InputText.style'
import { memo, useEffect, useState } from 'react'
import spaceStyle from '@/styles/space'
import textAlign from '@/styles/text_align'
import { Heading4 } from '@/components/base/Heading'
import InputErrorLabel from '../InputErrorLabel'

interface InputTextProps {
  name: string
  label?: string
  subLabel?: string
  smallLabel?: string
  placeholder?: string
  prefixIcon?: string
  prefixRight?: boolean
  placeholderFontSize?: string
  maxWidth?: number | string
  margin?: string
  type?: string
  defaultValue?: string | number
  value?: string | number
  maxLength?: number
  disabled?: boolean
  autoComplete?: 'on' | 'off'
  unit?: string
  errorMessage?: string
  hasError?: boolean
  onPressPrefixIcon?: () => void
  onChange?: (value: string) => void
  onValidateError?: (text: string) => string | undefined
  onClick?: () => void
}

interface InputPrefixProps {
  prefixIcon?: string
  onPressed?: () => void
}

interface InputLabelProps {
  label?: string
  subLabel?: string
  smallLabel?: string
}

const InputText = (props: InputTextProps): JSX.Element => {
  const globalStyles = useGlobalStyles()
  const styles = useStyles({
    maxWidth: props.maxWidth ?? 600,
    prefixIcon: props.prefixIcon !== undefined,
    prefixRight: props.prefixRight !== undefined,
    placeholderFontSize: props.placeholderFontSize,
    margin: props.margin
  })
  const [errorMessage, setError] = useState<undefined | string>(undefined)

  useEffect(() => {
    if (props.errorMessage != null) {
      setError(props.errorMessage)
    } else {
      setError(undefined)
    }
  }, [props.errorMessage])

  const onValidate = (text: string): void => {
    if (props.onValidateError !== undefined) {
      const errorMsg = props.onValidateError(text)
      setError(errorMsg)
    }
  }

  return (
    <div className={styles.container} onClick={props.onClick}>
      <InputLabel
        label={props.label}
        subLabel={props.subLabel}
        smallLabel={props.smallLabel}
      />
      <div className={styles.inputContainer}>
        <InputPrefix
          prefixIcon={props.prefixIcon}
          onPressed={props.onPressPrefixIcon}
        />
        <div className={styles.inputBlock}>
          <input
            defaultValue={props.defaultValue}
            type={props.type ?? 'text'}
            id={props.name}
            name={props.name}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            className={`${styles.input} ${
              errorMessage !== undefined || props.hasError === true
                ? globalStyles.borderError
                : globalStyles.border
            }`}
            autoComplete={props.autoComplete}
            onChange={(e) => {
              if (props.onChange === undefined) {
                return
              }
              props.onChange(e.target.value)
              onValidate(e.target.value)
            }}
            value={props.value}
            disabled={props.disabled ?? false}
          />
          {props.unit !== undefined && (
            <div className={styles.inputUnit}>{props.unit}</div>
          )}
        </div>
      </div>
      {errorMessage !== undefined && <InputErrorLabel message={errorMessage} />}
    </div>
  )
}

const InputPrefix = (props: InputPrefixProps): JSX.Element => {
  return props.prefixIcon !== undefined ? (
    <button type="button" onClick={props.onPressed}>
      <img src={props.prefixIcon} alt="" height={16} width={16} />
    </button>
  ) : (
    <div />
  )
}

const InputLabel = (props: InputLabelProps): JSX.Element => {
  const globalStyles = useGlobalStyles()
  const styles = useStyles({
    maxWidth: 600
  })

  if (props.smallLabel !== undefined) {
    return (
      <div className={styles.label}>
        <Heading4
          fixedSPfontSize
          textAlign={textAlign.LEFT}
          margin={{ all: spaceStyle.NONE }}
        >
          {props.smallLabel}
        </Heading4>
      </div>
    )
  }

  return props.label !== undefined ? (
    <div className={styles.label}>
      <span className={globalStyles.spHeadline3text}>
        {props.label}&nbsp;&nbsp;
      </span>
      <span className={globalStyles.spCaptiontext}>{props.subLabel}</span>
    </div>
  ) : (
    <div />
  )
}

export default memo(InputText)
