import { type AlignKeyType } from '@/styles/display'
import { type HTMLAttributes, type ReactNode } from 'react'
import styled from 'styled-components'

interface ColProps extends HTMLAttributes<HTMLDivElement> {
  span?: number
  xs?: number
  sm?: number
  align?: AlignKeyType
  children?: ReactNode
  width?: string
  height?: string
  justify?: AlignKeyType
}

const StyledCol = styled.div<ColProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: ${(props) => props.align !== undefined && props.align};
  justify-content: ${(props) => props.justify ?? 'stretch'};
  width: ${(props) => {
    if (props.width !== undefined) {
      return props.width
    }
    return props.span !== undefined ? `${(props.span / 24) * 100}%` : 'auto'
  }};
  height: ${(props) => props.height !== undefined && `${props.height}`};
  @media (max-width: 768px) {
    width: ${(props) => props.sm !== undefined && `${(props.sm / 24) * 100}%`};
  }
  @media (max-width: 576px) {
    width: ${(props) => props.xs !== undefined && `${(props.xs / 24) * 100}%`};
  }
`

const Col: React.FC<ColProps> = (props: ColProps) => {
  return <StyledCol {...props}>{props.children}</StyledCol>
}

export default Col
