import React, { type ReactNode } from 'react'
import {
  type NavigateFunction,
  useNavigate,
  type NavigateOptions
} from 'react-router-dom'
import { type UseAuth, useAuth } from './useAuth'
import { AppPath } from '@/routes/routes'
import {
  V1RouteType,
  type V1GetJobPostingSearchResultViewRequest
} from 'my-recruiter-app-ts-if'
import { useApp, type App } from './useApp'
import { showErrorToast } from '@/components/SnackBar/BaseSnackBar'

interface Props {
  children?: ReactNode
}

export const NavigatorContext = React.createContext<Navigator | undefined>(
  undefined
)

class Navigator {
  private readonly navi: NavigateFunction
  private readonly auth: UseAuth
  private readonly app: App

  public constructor(navi: NavigateFunction, auth: UseAuth, app: App) {
    this.navi = navi
    this.auth = auth
    this.app = app
  }

  public async gotoSignIn(): Promise<void> {
    this.navi(AppPath.SignIn)
  }

  public async gotoSignUp(): Promise<void> {
    this.navi(AppPath.SignUp)
  }

  public async gotoSignUpComplete(): Promise<void> {
    this.navi(AppPath.SignUpComplete)
  }

  public async gotoWithdrawComplete(): Promise<void> {
    this.navi(AppPath.JobSeekerWithdrawComplete)
  }

  private async verifyAndGotoPath(
    path: string,
    options?: NavigateOptions
  ): Promise<void> {
    if (this.app.havePrivatePage) {
      await this.auth
        .verify()
        .then((res) => {
          if (res) {
            this.navi(path, options)
          } else {
            this.navi(AppPath.SignIn)
          }
        })
        .catch(() => {})
    }
  }

  public async gotoNotFound(): Promise<void> {
    this.navi(AppPath.NotFound)
  }

  public async gotoTop(): Promise<void> {
    void this.verifyAndGotoPath(AppPath.Top)
  }

  public async gotoForgotPasswordComplete(): Promise<void> {
    this.navi(AppPath.ForgotPasswordComplete)
  }

  public async gotoConfirmForgotPasswordComplete(): Promise<void> {
    this.navi(AppPath.ConfirmForgotPasswordComplete)
  }

  public async gotoJobPostingDetail(id?: string): Promise<void> {
    void this.verifyAndGotoPath(
      AppPath.JobPostingDetail.replace(':id', id?.toString() ?? '')
    )
  }

  public async gotoJobPostingAdvancedSearch(): Promise<void> {
    void this.verifyAndGotoPath(AppPath.JobPostingAdvancedSearch)
  }

  public async gotoJobPostingAdvancedSearcResult(): Promise<void> {
    void this.verifyAndGotoPath(AppPath.JobPostingSearchResult)
  }

  public async gotoJobPostingSearchResult({
    getJobPostingSearchResultViewRequest
  }: {
    getJobPostingSearchResultViewRequest: V1GetJobPostingSearchResultViewRequest
  }): Promise<void> {
    void this.verifyAndGotoPath(AppPath.JobPostingSearchResult, {
      state: { getJobPostingSearchResultViewRequest }
    })
  }
  // TODO 本当はここから上のrouteに関してはthis.verifyAndGotoPathで囲まない

  public async goBack(): Promise<void> {
    this.navi(-1)
  }

  // ここから以下はprivate only
  public async gotoSuspendComplete(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.JobSeekerSuspendComplete)
  }

  public async gotoJobSeekerSchedule(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.JobSeekerSchedule)
  }

  public async gotoJobSeekerMessageList(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.JobSeekerMessageList)
  }

  public async gotoJobSeekerMessageDetail(id: string): Promise<void> {
    await this.verifyAndGotoPath(
      AppPath.JobSeekerMessageDetail.replace(':id', id)
    )
  }

  public async gotoJobPostingsByProgressStatus(index?: number): Promise<void> {
    await this.verifyAndGotoPath(
      `${AppPath.JobPostingProgressStatus}?tab=${index ?? 0}`
    )
  }

  public async gotoPasswordSettingCompleted(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.PasswordSettingComplete)
  }

  public async gotoFavoriteJobposting(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.FavoriteJobposting)
  }

  public async gotoSavedJobPostingSearchCondition(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.JobPostingSearchConditions)
  }

  public async gotoBrowsingHistory(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.BrowsingHistory)
  }

  public async gotoJobPostingInquired(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.JobPostingInquired)
  }

  public async gotoProfile(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.Profile)
  }

  public async gotoJobPostingOfferAcceptance(id: string): Promise<void> {
    await this.verifyAndGotoPath(
      AppPath.JobPostingOfferAcceptance.replace(':id', id)
    )
  }

  public async gotoChangeID(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.ChangeID)
  }

  public async gotoChangeIDRequested(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.ChangeIDRequested)
  }

  public async gotoChangeIDConfirmful(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.ChangeIDConfirm)
  }

  public async gotoComposeJobSeekerMessage(): Promise<void> {
    await this.verifyAndGotoPath(AppPath.ComposeJobSeekerMessage)
  }

  public async goToRouteByRouteType(routeType?: V1RouteType): Promise<void> {
    switch (routeType) {
      case V1RouteType.Top:
        void this.gotoTop()
        break
      case V1RouteType.JobPostingAdvancedSearchResult:
        void this.gotoJobPostingAdvancedSearcResult()
        break
      case V1RouteType.JobPostingAdvancedSearch:
        void this.gotoJobPostingAdvancedSearch()
        break
      case V1RouteType.JobPostingsByProgressStatus:
        void this.gotoJobPostingsByProgressStatus()
        break
      case V1RouteType.SavedJobPostingSearchCondition:
        void this.gotoSavedJobPostingSearchCondition()
        break
      case V1RouteType.JobSeekerSchedule:
        void this.gotoJobSeekerSchedule()
        break
      case V1RouteType.JobSeekerProfile:
        void this.gotoProfile()
        break
      case V1RouteType.JobSeekerMessageList:
        void this.gotoJobSeekerMessageList()
        break
      case V1RouteType.FavoriteJobPostings:
        await this.gotoFavoriteJobposting()
        break
      case V1RouteType.JobPostingJobSeekerAction:
        void this.gotoJobPostingInquired()
        break
      case V1RouteType.AppSetting:
        await this.verifyAndGotoPath(AppPath.Setting)
        break
      case V1RouteType.ComposeJobSeekerMessage:
        void this.gotoComposeJobSeekerMessage()
        break
      default:
        showErrorToast('unimplemented route')
        break
    }
  }
}

export const NavigatorProvider: React.FC<Props> = (props) => {
  const navigator = new Navigator(useNavigate(), useAuth(), useApp())

  return (
    <NavigatorContext.Provider value={navigator}>
      {props.children}
    </NavigatorContext.Provider>
  )
}

export function useNavigator(): Navigator | undefined {
  return React.useContext(NavigatorContext)
}
