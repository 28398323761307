import { memo } from 'react'

import { type V1FormElementState } from 'my-recruiter-app-ts-if'
import { Text3 } from '@/components/base/Text'
import { align, display } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { showFormValue } from './formText'
import { Block } from '@/components/base/Block'
import { Link } from '@/components/base/Link'
import { AppPath } from '@/routes/routes'

export interface FormElementUserIdFieldProps {
  isEdit?: boolean
  formElementState: V1FormElementState
}

const FormElementUserIdField = ({
  isEdit = true,
  formElementState
}: FormElementUserIdFieldProps): JSX.Element => {
  const stateValue =
    formElementState.contentStates?.at(0)?.text ??
    formElementState.contentStates?.at(0)?.uint32 ??
    ''

  return (
    <>
      {isEdit && (
        <Block
          display={display.FLEX}
          alignItems={align.LEFT}
          margin={{ bottom: spaceStyle.M }}
        >
          <Link to={AppPath.ChangeID} underline={true} target="_blank">
            ログインIDの変更はこちら
          </Link>
        </Block>
      )}
      <Block display={display.FLEX} alignItems={align.LEFT}>
        <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
          {showFormValue(stateValue)}
        </Text3>
      </Block>
    </>
  )
}

export default memo(FormElementUserIdField)
