import { getSelectorElements } from '@/api/component-service-api'
import {
  type V1SelectorElement,
  type V1SelectorComponent
} from 'my-recruiter-app-ts-if'
import type React from 'react'
import {
  type FormElementsSelectorAction,
  FETCHING_DATA_SELECTOR_ELEMENTS,
  ADD_SELECTOR_ELEMENTS,
  RESET,
  ON_RESET_SELECT,
  type FormElementsSelectorState,
  ON_SELECT_ALL,
  ON_SELECT,
  ON_BACK,
  ADD_DEFAULT_VALUES_SELECTOR_ELEMENTS
} from './reducer'
import _ from 'lodash'

const fetchData = async ({
  selectorComponentId,
  targetId,
  depth
}: {
  selectorComponentId: string
  targetId?: string
  depth?: number
}): Promise<V1SelectorComponent | undefined> => {
  const view = await getSelectorElements({
    selectorComponentId,
    targetId,
    depth
  })
  return view.component
}

export const initMain = (
  selectorComponentId: string,
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: FETCHING_DATA_SELECTOR_ELEMENTS })
  fetchData({ selectorComponentId })
    .then((value) => {
      dispatch({ type: ADD_SELECTOR_ELEMENTS, payload: value })
    })
    .finally(() => {
      dispatch({ type: FETCHING_DATA_SELECTOR_ELEMENTS })
    })
}

export const onReset = (
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: RESET })
}

export const onResetSelected = (
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: ON_RESET_SELECT })
}

export const onLoadNextDepth = (
  target: V1SelectorElement,
  state: FormElementsSelectorState,
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  const { items } = state
  const component = _.last(items)
  if (component?.selectorComponentId === undefined) return

  const { selectorComponentId, depth, maxDepth } = component

  if (depth === undefined || maxDepth === undefined) return

  if (depth + 1 > maxDepth) return

  dispatch({ type: FETCHING_DATA_SELECTOR_ELEMENTS, payload: target })
  fetchData({ selectorComponentId, targetId: target.id, depth: depth + 1 })
    .then((value) => {
      dispatch({ type: ADD_SELECTOR_ELEMENTS, payload: value })
    })
    .finally(() => {
      dispatch({ type: FETCHING_DATA_SELECTOR_ELEMENTS })
    })
}

export const onSelect = (
  target: V1SelectorElement,
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: ON_SELECT, payload: target })
}

export const onSelectAll = (
  checked: boolean,
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: ON_SELECT_ALL, payload: checked })
}

export const onBack = (
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: ON_BACK })
}

export const addDefaultValues = (
  values: string[],
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
): void => {
  dispatch({ type: ADD_DEFAULT_VALUES_SELECTOR_ELEMENTS, payload: values })
}

export const getSelectedLabels = (
  state: FormElementsSelectorState
): string[] => {
  return state.values.reduce<string[]>((previous, value) => {
    const label = state.allElements.find((e) => {
      return e.data === value
    })?.label
    if (label !== undefined) return [...previous, label]
    return previous
  }, [])
}
