import MesageFileUpload from '@/components/FileUpload/MesageFileUpload'
import { Block } from '@/components/base/Block'
import { size } from '@/styles/device'
import spaceStyle from '@/styles/space'
import {
  type V1Component,
  V1ComponentType,
  type V1FormComponent,
  type V1Screen
} from 'my-recruiter-app-ts-if'
import { memo } from 'react'
import { BaseComponentBuilder } from '../Builder'
import { ComponentWrapper } from '../Builder/Component/ComponentWrapper'
import JobSeekerNewMessageContainer from './containers/JobSeekerNewMessageContainer'

interface ComponentProps {
  screen?: V1Screen
  component: V1Component
  refetch?: () => void
}

function ComposeJobSeekerMessageBuilder(props: ComponentProps): JSX.Element {
  switch (props.component.type) {
    case V1ComponentType.JobSeekerMessage:
      return (
        <Block
          margin={{ vertical: spaceStyle.M, horizontal: spaceStyle.AUTO }}
          maxWidth={size.laptop}
          style={{ position: 'unset' }}
        >
          <JobSeekerNewMessageContainer
            screen={props.screen}
            component={props.component.component as V1FormComponent}
            key={`ComposeJobSeekerMessageBuilder_${props.component.id ?? 0}`}
          />
        </Block>
      )
    case V1ComponentType.JobSeekerMessageFileUploader:
      return (
        <ComponentWrapper
          key={`ComposeJobSeekerMessageBuilder_${props.component.id ?? 0}`}
        >
          <MesageFileUpload />
        </ComponentWrapper>
      )
    default:
      return (
        <BaseComponentBuilder
          key={`JobSeekerCreateMessageBuilder_${props.component.id ?? 0}`}
          component={props.component}
        ></BaseComponentBuilder>
      )
  }
}

export default memo(ComposeJobSeekerMessageBuilder)
