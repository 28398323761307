import { type V1FormElement } from 'my-recruiter-app-ts-if'

export const generateUUID = (): string => {
  let uuid = ''
  for (let i = 0; i < 32; i++) {
    const randomValue = (Math.random() * 16) | 0
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-'
    }
    uuid += (
      i === 12 ? 4 : i === 16 ? (randomValue & 3) | 8 : randomValue
    ).toString(16)
  }
  return uuid
}

export const formElementStateMapKey = (element: V1FormElement): string => {
  return element?.state?.key != null
    ? `${element.state.key}-${element.state.keyIndex ?? 0}`
    : ''
}
