import { getScreensByType } from '@/api/screen-service-api'
import { Loading } from '@/components/base/Loading/Loading'
import {
  type V1Component,
  type V1Screen,
  type V1ScreenType
} from 'my-recruiter-app-ts-if'
import { type ScreenServiceGetByTypeRequest } from 'my-recruiter-app-ts-if/dist/apis/ScreenServiceApi'
import { useEffect, useState } from 'react'
import { buildComponent } from '../Component/BaseComponentBuilder'
import { isMsgScreenType } from '@/utils/constant'
import { Block } from '@/components/base/Block'
import { align, display } from '@/styles/display'
import { fontSize, fontWeight } from '@/styles/font'

interface Props {
  request?: ScreenServiceGetByTypeRequest
  screenType?: V1ScreenType
  screen?: V1Screen
  componentBuilder?: (component: V1Component) => JSX.Element
}

const ScreenByType = (props: Props): JSX.Element => {
  const [components, setComponents] = useState<V1Component[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { request, screenType, componentBuilder } = props

  useEffect(() => {
    if (props.screen !== undefined) {
      setComponents(props.screen?.components ?? [])
    }
    void onLoadData()
    return () => {}
  }, [])

  const onLoadData = async (): Promise<void> => {
    if (request !== undefined) {
      const res = await getScreensByType(request)
      setComponents(res?.screen?.components ?? [])
      setIsLoading(false)
      return
    }

    if (screenType !== undefined) {
      const res = await getScreensByType({ screenType })
      setComponents(res?.screen?.components ?? [])
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {components.length > 0 ? (
        components.map((e) =>
          componentBuilder !== undefined
            ? componentBuilder(e)
            : buildComponent(e)
        )
      ) : request !== undefined && isMsgScreenType(request.screenType) ? (
        <Block
          height="calc(100vh - 250px)"
          justifyContent={align.CENTER}
          alignItems={align.CENTER}
          display={display.FLEX}
          fontSize={fontSize.M}
          fontWeight={fontWeight.Regular}
        >
          通知はありません
        </Block>
      ) : (
        ''
      )}
    </>
  )
}

export default ScreenByType
