import {
  type ScreenServiceGetByTypeScreenTypeEnum,
  V1ScreenType
} from 'my-recruiter-app-ts-if'

export const listMessageType: ScreenServiceGetByTypeScreenTypeEnum[] = [
  V1ScreenType.JobSeekerInboxMessageList,
  V1ScreenType.JobSeekerSentMessageList,
  V1ScreenType.JobSeekerDraftMessageList,
  V1ScreenType.JobSeekerTrashMessageList,
  V1ScreenType.ComposeJobSeekerMessage
]

export const isMsgScreenType = (screenType: V1ScreenType): boolean => {
  return listMessageType.includes(screenType)
}
