import { memo } from 'react'

import {
  type V1FormElementState,
  type V1FormElementUiTextField,
  type V1FormElementContentState
} from 'my-recruiter-app-ts-if'
import { InputText } from '@/components/base/Form/InputText'
import { Text3 } from '@/components/base/Text'
import { align, display } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { showFormValue } from '../formText'
import textAlign from '@/styles/text_align'
import { fontWeight } from '@/styles/font'
import { Block } from '@/components/base/Block'
import useIsMobile from '@/hooks/useMobile'

export interface FormElementInlineTextFieldProps {
  label?: string
  isEdit?: boolean
  name: string
  textFieldUiIFM: V1FormElementUiTextField
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementInlineTextField = ({
  label,
  isEdit = true,
  textFieldUiIFM,
  formElementState,
  elemenErrorMessage,
  onChange,
  name
}: FormElementInlineTextFieldProps): JSX.Element => {
  const isMobile = useIsMobile()

  const stateValue = formElementState.contentStates?.at(0)?.text ?? ''
  const unit = textFieldUiIFM.unit

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT') {
    const onChangeText = (value: string): void => {
      onChange({
        ...formElementState,
        contentStates: [
          {
            text: value
          } satisfies V1FormElementContentState
        ]
      })
    }

    const textFieldLabel = (
      <Block width={spaceStyle.XXXXXL}>
        <Text3
          textAlign={textAlign.LEFT}
          fontWeight={fontWeight.Bold}
          margin={{ all: spaceStyle.NONE }}
          padding={{ vertical: spaceStyle.S }}
        >
          {label}
        </Text3>
      </Block>
    )

    return (
      <Block
        display={display.FLEX}
        margin={{ bottom: spaceStyle.LL }}
        padding={{ right: isMobile ? spaceStyle.NONE : spaceStyle.XXXXXL }}
      >
        {textFieldLabel}
        {isEdit ? (
          <InputText
            maxWidth="100%"
            type={'text'}
            value={stateValue}
            name={name}
            onChange={(value) => {
              onChangeText(value)
            }}
            placeholder={textFieldUiIFM?.placeholder}
            unit={unit}
            errorMessage={elemenErrorMessage}
          />
        ) : (
          <Block display={display.FLEX} alignItems={align.CENTER}>
            <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
              {showFormValue(stateValue)}
            </Text3>
            {unit !== undefined && <Text3>{unit}</Text3>}
          </Block>
        )}
      </Block>
    )
  }

  return <></>
}

export default memo(FormElementInlineTextField)
