import { showErrorToast, showToast } from '@/components/SnackBar/BaseSnackBar'
import { useMoveTmpMessageFilesToPersistenceMutation } from '@/feature/JobSeeker/api/moveTmpMessageFilesToPersistence'
import { useUploadTempFileToS3Mutation } from '@/feature/JobSeeker/api/uploadTempFileToS3'

export enum UploadFileType {
  MessageFile
}

export interface FileUpload {
  invokeUpload: () => Promise<string[]>
}

export const useFileUploadMessage = (
  tmpFileUploadUrlQueries: any,
  files: File[]
): FileUpload => {
  const uploadTempFileToS3Mutation = useUploadTempFileToS3Mutation()
  const moveTmpMessageFilesToPersistenceMutation =
    useMoveTmpMessageFilesToPersistenceMutation()

  const invokeUpload = async (): Promise<string[]> => {
    try {
      const uploadFileUrls: string[] = []
      for (let i = 0; i < tmpFileUploadUrlQueries.length; i++) {
        const tmpFileUploadUrlQuery = tmpFileUploadUrlQueries[i]
        if (
          Boolean(tmpFileUploadUrlQuery.isSuccess) &&
          tmpFileUploadUrlQuery.data?.presignedUrl != null
        ) {
          uploadFileUrls.push(tmpFileUploadUrlQuery.data.presignedUrl)
        } else {
          console.error('failed to get tmp file upload url')
          showErrorToast('アップロードに失敗しました。')
          return []
        }
      }

      for (let i = 0; i < files.length; i++) {
        const dialogSelectedFile = files[i]
        if (dialogSelectedFile.size > 3 * 1024 * 1024) {
          showErrorToast('ファイルサイズは3MB以下である必要があります。')
          return []
        }
      }

      const uploadedFileNames: string[] = []

      for (let i = 0; i < files.length; i++) {
        const dialogSelectedFile = files[i]
        const uploadUrl = uploadFileUrls[i]

        await uploadTempFileToS3Mutation.mutateAsync({
          presignedUrl: uploadUrl,
          file: dialogSelectedFile
        })

        uploadedFileNames.push(dialogSelectedFile.name)
      }

      await moveTmpMessageFilesToPersistenceMutation.mutateAsync({
        body: {
          tmpFiles: uploadedFileNames.map((fileName) => {
            return { name: fileName }
          })
        }
      })

      showToast('アップロードが完了しました。')
      return uploadedFileNames
    } catch (error) {
      console.error(error)
      showErrorToast('アップロードに失敗しました。')
      return []
    }
  }

  return {
    invokeUpload
  }
}
