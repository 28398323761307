import { memo, useState } from 'react'
import {
  type V1FormElementState,
  V1FormElementStateType,
  type V1FormElementUiZipCode
} from 'my-recruiter-app-ts-if'
import { InputText } from '@/components/base/Form/InputText'
import { Row } from '@/components/base/Layout'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { SubButton } from '../base/Button'
import { align } from '@/styles/display'
import { buttonSize } from '../base/Button/Base'
import { Text3 } from '../base/Text'
import InputErrorLabel from '../base/Form/InputErrorLabel'

const rawGap = 12

export interface FormElementZipCodeProps {
  isEdit: boolean
  name: string
  zipCodeUiIFM: V1FormElementUiZipCode
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  searchAddressByZipCode?: (
    formElementUiZipCode: V1FormElementUiZipCode,
    formElementState: V1FormElementState,
    address: string
  ) => Promise<void>
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementZipCode = ({
  isEdit,
  name,
  zipCodeUiIFM,
  formElementState,
  elemenErrorMessage,
  searchAddressByZipCode,
  onChange
}: FormElementZipCodeProps): JSX.Element => {
  const zipCode = formElementState.contentStates?.at(0)?.text ?? ''
  const regex = /^(\d{1,})(?:-)?(\d{1,})$/
  const matches = zipCode.match(regex)

  const [firstHalf, secondHalf] =
    matches != null ? [matches[1].trim(), matches[2].trim()] : ['', '']

  const [zipCodeFull, setZipCodeFull] = useState<Record<string, string>>({
    first: firstHalf,
    second: secondHalf
  })

  if (!isEdit) {
    return (
      <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
        {zipCodeFull.first}-{zipCodeFull.second}
      </Text3>
    )
  }

  if (formElementState.type === V1FormElementStateType.Text) {
    const onChangeText = (value: string, key: string): void => {
      const newZipCodeFull = { ...zipCodeFull, [key]: value }
      setZipCodeFull(newZipCodeFull)
      onChange({
        ...formElementState,
        contentStates: [
          {
            text: `${newZipCodeFull.first}-${newZipCodeFull.second}`
          }
        ]
      })
    }

    return (
      <>
        <Row gutter={rawGap} align={align.CENTER}>
          <InputText
            maxWidth="48px"
            maxLength={3}
            value={zipCodeFull.first}
            name={name}
            hasError={elemenErrorMessage !== undefined}
            onChange={(value) => {
              onChangeText(value, 'first')
            }}
          />
          <span>-</span>
          <InputText
            maxWidth="56px"
            maxLength={4}
            value={zipCodeFull.second}
            name={name}
            hasError={elemenErrorMessage !== undefined}
            onChange={(value) => {
              onChangeText(value, 'second')
            }}
          />
          <SubButton
            maxWidth="72px"
            height={buttonSize.S}
            onClick={() => {
              if (searchAddressByZipCode != null) {
                void searchAddressByZipCode(
                  zipCodeUiIFM,
                  formElementState,
                  `${zipCodeFull.first}${zipCodeFull.second}`
                )
              }
            }}
            fontSize={fontSize.S}
            padding={{
              vertical: spaceStyle.M,
              horizontal: spaceStyle.XL
            }}
          >
            検索
          </SubButton>
        </Row>
        {elemenErrorMessage !== undefined && (
          <InputErrorLabel message={elemenErrorMessage} />
        )}
      </>
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementZipCode)
