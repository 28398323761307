import {
  type V1Component,
  V1ComponentType,
  type V1TodoListComponent,
  type V1ParagraphListComponent,
  type V1JobSeekerAvailableScheduleComponent,
  type V1JobPostingSearchComponent,
  type V1JobPostingCardsComponent,
  type V1ProgressStatusComponent,
  type V1JobPostingSimpleCardsComponent,
  type V1MyProfileNavigationListComponent,
  type V1Screen,
  type V1SelectorComponent,
  type V1InterviewConfirmedJobPostingCardsComponent
} from 'my-recruiter-app-ts-if'

import { Block } from '@/components/base/Block'
import spaceStyle from '@/styles/space'
import { lazy, type ReactNode } from 'react'

const FormElementSelectorComponentContainer = lazy(
  async () =>
    await import(
      '@/feature/Builder/Component/containers/FormElementsSelectorPopupContainer/FormElementSelectorComponentContainer'
    )
)

const TodoListContainer = lazy(
  async () =>
    await import(
      '@/feature/Builder/Component/containers/TodoListContainer/TodoListContainer'
    )
)

const InterviewScheduleCollectionContainer = lazy(
  async () =>
    await import(
      '@/feature/Builder/Component/containers/InterviewScheduleCollectionContainer/InterviewScheduleCollectionContainer'
    )
)

const ParagraphListContainer = lazy(
  async () =>
    await import(
      '@/feature/Builder/Component/containers/ParagraphListContainer'
    )
)
const JobPostingCardListContainer = lazy(
  async () =>
    await import(
      './containers/JobPostingCardListContainer/JobPostingCardListContainer'
    )
)

const InterviewConfirmedJobPostingCardsContainer = lazy(
  async () =>
    await import(
      './containers/InterviewConfirmedJobPostingCardsContainer/InterviewConfirmedJobPostingCardsContainer'
    )
)

const JobPostingSimpleCardsContainer = lazy(
  async () =>
    await import(
      './containers/JobPostingSimpleCardsContainer/JobPostingSimpleCardsContainer'
    )
)

const JobPostingSearchContainer = lazy(
  async () => await import('./containers/JobPostingSearchContainer')
)

// 以下コンテナ経由でないのでリファクタした方が良い？
const ProgressStatus = lazy(
  async () =>
    await import('@/components/ProgressStatus').then(({ ProgressStatus }) => ({
      default: ProgressStatus
    }))
)

const MyProfileNavigationListComponent = lazy(
  async () =>
    await import('@/components/MyProfileNavigationList/MyProfileNavigationList')
)

const JobPostingCards = lazy(
  async () =>
    await import(
      '@/feature/Builder/Component/components/JobPostingCards/JobPostingCards'
    )
)

interface ComponentProps {
  component: V1Component
  screen?: V1Screen
}

interface ComponentWrapperProps {
  children: ReactNode
}

function ComponentWrapper({ children }: ComponentWrapperProps): JSX.Element {
  return (
    <Block
      margin={{ vertical: spaceStyle.XXXL, horizontal: spaceStyle.AUTO }}
      maxWidth="1080px"
    >
      {children}
    </Block>
  )
}

function BaseComponentBuilder(props: ComponentProps): JSX.Element {
  // const navigator = useNavigator()
  switch (props.component.type) {
    case V1ComponentType.JobPostingCards:
      return (
        <ComponentWrapper>
          <JobPostingCardListContainer
            componentId={props.component.id ?? '0'}
            component={props.component.component as V1JobPostingCardsComponent}
            getJobPostingCardsSearchBody={{}}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.TodoList:
      return (
        <ComponentWrapper>
          <TodoListContainer
            todoListComponent={props.component.component as V1TodoListComponent}
            componentId={props.component.id ?? '0'}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.JobPostingSearch:
      return (
        <ComponentWrapper>
          <JobPostingSearchContainer
            component={props.component.component as V1JobPostingSearchComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.ParagraphList:
      return (
        <ComponentWrapper>
          <ParagraphListContainer
            component={props.component.component as V1ParagraphListComponent}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.JobSeekerAvailableSchedule:
      return (
        <ComponentWrapper>
          <InterviewScheduleCollectionContainer
            interviewScheduleCollectionComponent={
              props.component.component as V1JobSeekerAvailableScheduleComponent
            }
            componentId={props.component.id ?? '0'}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.ProgressStatus:
      return (
        <ComponentWrapper>
          <ProgressStatus
            component={props.component.component as V1ProgressStatusComponent}
            componentId={props.component.id ?? '0'}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.JobPostingSimpleCards:
      return (
        <ComponentWrapper>
          <JobPostingSimpleCardsContainer
            component={
              props.component.component as V1JobPostingSimpleCardsComponent
            }
          />
        </ComponentWrapper>
      )
    case V1ComponentType.JobPostingSimpleCardsWithImage:
      return (
        <ComponentWrapper>
          <JobPostingSimpleCardsContainer
            withImage={true}
            component={
              props.component.component as V1JobPostingSimpleCardsComponent
            }
          />
        </ComponentWrapper>
      )
    case V1ComponentType.InterviewConfirmedJobPostingCards:
      return (
        <ComponentWrapper>
          <InterviewConfirmedJobPostingCardsContainer
            component={
              props.component
                .component as V1InterviewConfirmedJobPostingCardsComponent
            }
          />
        </ComponentWrapper>
      )
    case V1ComponentType.MyProfileNavigationList:
      return (
        <ComponentWrapper>
          <MyProfileNavigationListComponent
            component={
              props.component.component as V1MyProfileNavigationListComponent
            }
            componentId={props.component.id ?? '0'}
          />
        </ComponentWrapper>
      )
    case V1ComponentType.Selector:
      return (
        <FormElementSelectorComponentContainer
          key={props.component.id}
          component={props.component.component as V1SelectorComponent}
        />
      )
    default:
      console.log(
        `${props.component.type?.toString() ?? ''} not implement component`
      )
      return <></>
  }
}

export const buildComponent = (component: V1Component): JSX.Element => {
  switch (component.type) {
    case V1ComponentType.JobPostingCards:
      return (
        <JobPostingCards
          key={component.id}
          componentId={component.id}
          component={component.component as V1JobPostingCardsComponent}
        />
      )
    default:
      console.log(`${component.type?.toString() ?? ''} not implement component`)
      return <></>
  }
}

export default BaseComponentBuilder
