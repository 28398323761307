import styled from 'styled-components'
import { Text3 } from '../Text'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import { useApp } from '@/hooks/useApp'
import spaceStyle from '@/styles/space'
import { useLocation } from 'react-router-dom'
import { type AppPath } from '@/routes/routes'

export interface Tab {
  title: string
  url?: AppPath
}

export interface TabViewProps {
  tabs: Tab[]
}

const StyledTabs = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledTab = styled.a<{
  borderColor?: V1AppColorTheme
  textColor?: V1AppColorTheme
}>`
  flex: 1;
  border: none;
  background: none;
  text-decoration: none;
  color: ${(props) => props.textColor?.color ?? 'black'};
  cursor: pointer;
  border-bottom: 2px solid
    ${(props) => props.borderColor?.color ?? 'transparent'};
  white-space: nowrap;
`

const TabView: React.FC<TabViewProps> = ({ tabs }: TabViewProps) => {
  const app = useApp()
  const location = useLocation()

  return (
    <StyledTabs>
      {tabs.map((tab, index) => (
        <StyledTab
          key={index}
          borderColor={
            location.pathname === tab.url
              ? app.theme.primaryMainColor
              : undefined
          }
          textColor={app.theme.mainTextColor}
          href={tab.url}
        >
          <Text3
            margin={{ all: spaceStyle.M }}
            fontWeight={location.pathname === tab.url ? '700' : '400'}
          >
            {tab.title}
          </Text3>
        </StyledTab>
      ))}
    </StyledTabs>
  )
}

export default TabView
