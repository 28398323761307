import { Block } from '@/components/base/Block'
import { Heading4 } from '@/components/base/Heading'
import { Icon, LocalIconType } from '@/components/base/Icon'
import { Row } from '@/components/base/Layout'
import { Text4 } from '@/components/base/Text'
import { useApp } from '@/hooks/useApp'
import { align } from '@/styles/display'
import { fontSize } from '@/styles/font'
import spaceStyle, { buildSpaceStyle, type SpacingProps } from '@/styles/space'
import textAlign from '@/styles/text_align'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import { memo } from 'react'
import styled from 'styled-components'

interface StyledSelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  borderColor?: V1AppColorTheme
  backgroundColor?: V1AppColorTheme
  textColor?: V1AppColorTheme
  focusColor?: V1AppColorTheme
  height?: string
  padding?: SpacingProps
}

const StyledSelect = styled.select<StyledSelectProps>`
  padding: ${spaceStyle.L} ${spaceStyle.M};
  border: 1px solid
    ${(props) => props.borderColor?.color ?? 'rgba(0, 0, 0, 0.12)'};
  border-radius: 4px;
  color: ${(props) => props.textColor?.color ?? 'rgba(0, 0, 0, 0.87)'};
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: ${(props) => props.backgroundColor?.color ?? 'white'};
  height: ${(props) => props.height !== undefined && props.height};
  padding: ${(props) => {
    return props.padding !== undefined
      ? buildSpaceStyle(props.padding)
      : `${spaceStyle.L} ${spaceStyle.M}`
  }};
`

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  pointer-events: none;
`

interface SelectBoxColProps {
  gap: number
  labelWidth: number
}
const SelectBoxCol = styled.div<SelectBoxColProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `calc(100% - ${props.gap}px - ${props.labelWidth}px)`};
`

interface SelectBoxLabelColProps {
  width: number
}
const SelectBoxLabelCol = styled.div<SelectBoxLabelColProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.width}px`};
  width: 12px;
`

export interface GenericFormElementSelectBoxOption {
  value: number | string
  label: string
}

export interface SelectBoxStyle {
  height?: string
  padding?: SpacingProps
}
export interface SelectBoxLabel {
  top?: string
  right?: string
}

interface GenericFormElementSelectBoxProps {
  options: GenericFormElementSelectBoxOption[]
  noSelectedLable?: string
  selectedValue: number | string | undefined
  disabled?: boolean
  style?: SelectBoxStyle
  label?: SelectBoxLabel
  hasError?: boolean
  onChange: (
    newSelectedValue: number | string | undefined,
    label?: string
  ) => void
}

function GenericFormElementSelectBox({
  options,
  noSelectedLable = '指定なし',
  selectedValue,
  disabled,
  style,
  label,
  hasError,
  onChange
}: GenericFormElementSelectBoxProps): JSX.Element {
  const app = useApp()
  const rightLabelStyle = {
    gap: 4,
    width: 12
  }

  return (
    <>
      {label?.top !== undefined && (
        <Heading4
          fixedSPfontSize
          textAlign={textAlign.LEFT}
          margin={{ bottom: spaceStyle.S }}
        >
          {label?.top}
        </Heading4>
      )}
      <Row gutter={rightLabelStyle.gap} align={align.CENTER}>
        <SelectBoxCol
          gap={rightLabelStyle.gap}
          labelWidth={label?.right !== undefined ? rightLabelStyle.width : 0}
        >
          <Block style={{ position: 'relative' }}>
            <StyledSelect
              disabled={disabled}
              value={selectedValue ?? ''}
              borderColor={
                hasError === true ? app.theme.errorMainColor : undefined
              }
              backgroundColor={
                hasError === true ? app.theme.errorUltralightColor : undefined
              }
              onChange={(event) => {
                const newSelectedValue =
                  event.target.options[event.target.options.selectedIndex].value
                if (newSelectedValue === '') {
                  onChange(undefined)
                } else {
                  onChange(newSelectedValue)
                }
              }}
              height={style?.height}
              padding={style?.padding}
            >
              <option value={''}>{noSelectedLable}</option>
              {options?.map((option) => (
                <option
                  key={`option_${option.label}_${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </StyledSelect>
            <IconContainer>
              <Icon
                iconType={LocalIconType.ExpandMore}
                iconStyle={{
                  fontSize: fontSize.XL,
                  theme:
                    (disabled ?? false)
                      ? app.theme.disabledColor
                      : app.theme.mainTextColor
                }}
              />
            </IconContainer>
          </Block>
        </SelectBoxCol>
        {label?.right !== undefined && (
          <SelectBoxLabelCol width={rightLabelStyle.width}>
            <Text4
              textColor={
                (disabled ?? false)
                  ? app.theme.disabledColor
                  : app.theme.mainTextColor
              }
            >
              {label?.right}
            </Text4>
          </SelectBoxLabelCol>
        )}
      </Row>
    </>
  )
}

export default memo(GenericFormElementSelectBox)
