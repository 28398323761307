import type { AppTheme } from '@/hooks/useApp'
import { createUseStyles } from 'react-jss'

interface StyleProps {
  maxWidth: number | string
  prefixIcon?: boolean
  prefixRight?: boolean
  placeholderFontSize?: string
  margin?: string
}

const useStyles = createUseStyles<string, StyleProps, AppTheme>((theme) => ({
  container: {
    width: '100%',
    maxWidth: (props) => props.maxWidth,
    margin: (props) => props.margin,
    display: 'flex',
    flexDirection: 'column'
  },
  inputContainer: {
    height: 36,
    maxWidth: (props) => props.maxWidth,
    backgroundColor: theme.whiteTextColor.color,
    position: 'relative',
    '& button': {
      position: 'absolute',
      right: (props) => (props.prefixRight === true ? 6 : undefined),
      left: (props) => (props.prefixRight === true ? undefined : 6),
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 36,
      height: 36,
      background: 'transparent',
      border: 'none'
    }
  },
  inputBlock: {
    height: 36,
    alignItems: 'center',
    display: 'flex',
    '& input:focus': {
      outline: `1px solid ${theme.primaryMainColor.color ?? 'white'}`
    },
    '& input::placeholder': {
      color: theme.subTextColor.color ?? 'black',
      fontSize: (props) => props.placeholderFontSize ?? 10
    }
  },
  inputUnit: {
    width: '8%'
  },
  input: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    padding: (props) =>
      props.prefixIcon === true
        ? props.prefixRight === true
          ? '0px 42px 0px 12px'
          : '0px 12px 0px 42px'
        : '0px 12px',
    boxSizing: 'border-box'
  },
  label: {
    paddingBottom: 8,
    color: theme.mainTextColor.color ?? 'black',
    textAlign: 'left'
  },
  error: {
    color: theme.errorMainColor.color ?? 'red'
  }
}))

export default useStyles
