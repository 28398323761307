import type React from 'react'
import { createContext } from 'react'
import {
  initFormElementsSelectorState,
  useFormElementsSelector
} from './reducer'

export const FormElementsSelectorContext = createContext({
  ...initFormElementsSelectorState
})

export const FormElementsSelectorProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [state, dispatch] = useFormElementsSelector()

  const wrap = {
    state,
    dispatch
  }

  return (
    <FormElementsSelectorContext.Provider value={wrap}>
      {children}
    </FormElementsSelectorContext.Provider>
  )
}
