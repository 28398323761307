import {
  ComponentServiceApi,
  type V1GetEntryPointAreasResponse,
  type ComponentServiceGetEntryPointAreasRequest,
  type ComponentServiceGetPartAreasRequest,
  type V1GetPartAreasResponse,
  type ComponentServiceGetJobPostingCardsRequest,
  type V1GetJobPostingCardsResponse,
  type ComponentServiceGetSelectorElementsRequest,
  type V1GetSelectorElementsResponse
} from 'my-recruiter-app-ts-if'
import { config } from './api-config'

export const componentServiceApi = new ComponentServiceApi(config)

export const getJobPostingCards = async (
  request: ComponentServiceGetJobPostingCardsRequest
): Promise<V1GetJobPostingCardsResponse> => {
  return await componentServiceApi.componentServiceGetJobPostingCards(request)
}

export const getEntryPointAreas = async (
  request: ComponentServiceGetEntryPointAreasRequest
): Promise<V1GetEntryPointAreasResponse> => {
  return await componentServiceApi.componentServiceGetEntryPointAreas(request)
}

export const getPartAreas = async (
  request: ComponentServiceGetPartAreasRequest
): Promise<V1GetPartAreasResponse> => {
  return await componentServiceApi.componentServiceGetPartAreas(request)
}

/**
 *
 * @param request ComponentServiceGetSelectorElementsRequest
 *      elementType: ComponentServiceGetSelectorElementsElementTypeEnum;
 *      targetId?: string;
 *      depth?: number;
 * @returns V1GetSelectorElementsResponse
 */
export const getSelectorElements = async (
  request: ComponentServiceGetSelectorElementsRequest
): Promise<V1GetSelectorElementsResponse> => {
  return await componentServiceApi.componentServiceGetSelectorElements(request)
}
