import { type V1Screen, type V1SingleView } from 'my-recruiter-app-ts-if'
import { memo, type ReactNode } from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  max-width: 100%;
  margin: auto;
`

interface Props {
  view: V1SingleView
  children?: ReactNode
  screenBuilder?: (screen: V1Screen) => JSX.Element[]
}

const SingleView = ({ children, view, screenBuilder }: Props): JSX.Element => {
  if (view?.screen == null) {
    return <StyledDiv> {children}</StyledDiv>
  }

  return (
    <StyledDiv>
      {screenBuilder != null ? screenBuilder(view.screen) : <></>}
      {children}
    </StyledDiv>
  )
}

export default memo(SingleView)
