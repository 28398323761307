import {
  type V1FormElementUiSelectBox,
  type V1FormElementState,
  type V1FormElementContentLabelAndState,
  V1FormElementStateType
} from 'my-recruiter-app-ts-if'
import GenericFormElementSelectBox, {
  type SelectBoxStyle,
  type GenericFormElementSelectBoxOption
} from './GenericFormElementSelectBox'
import { Text3 } from '@/components/base/Text'
import { align } from '@/styles/display'
import spaceStyle from '@/styles/space'
import InputErrorLabel from '@/components/base/Form/InputErrorLabel'

export interface FormElementSelectBoxProps {
  isEdit?: boolean
  selectBoxUiIFM: V1FormElementUiSelectBox
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  formElementContents?: V1FormElementContentLabelAndState[]
  onChange: (newElementState: V1FormElementState) => void
  style?: SelectBoxStyle
}

export const generateOptions = (
  formElementStateType: V1FormElementStateType,
  options: V1FormElementContentLabelAndState[]
): GenericFormElementSelectBoxOption[] => {
  return (
    options?.map((option) => {
      return {
        label: option.label ?? 'label',
        value:
          formElementStateType === V1FormElementStateType.Text
            ? (option.state?.text ?? '')
            : (option.state?.uint32 ?? 0)
      }
    }) ?? []
  )
}

export const FormElementSelectBox = ({
  isEdit = true,
  selectBoxUiIFM,
  formElementState,
  formElementContents,
  elemenErrorMessage,
  onChange,
  style
}: FormElementSelectBoxProps): JSX.Element => {
  const selectedValue: number | string | undefined =
    formElementState.type === V1FormElementStateType.Text
      ? formElementState.contentStates?.at(0)?.text
      : (formElementState.contentStates?.at(0)?.uint32 ?? -1)

  const contents =
    (formElementContents?.length ?? 0) === 0
      ? (selectBoxUiIFM.contents ?? [])
      : (formElementContents ?? [])

  if (!isEdit) {
    const item = contents?.find(
      (e) =>
        (formElementState.type === V1FormElementStateType.Text
          ? e.state?.text
          : e.state?.uint32) === selectedValue
    )
    const label = item != null ? item.label : '-'

    return (
      <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
        {label}
        {selectBoxUiIFM.unit}
      </Text3>
    )
  }

  if (formElementState.type == null) {
    return <></>
  }

  const options = generateOptions(formElementState.type, contents)

  return (
    <>
      <div>
        <GenericFormElementSelectBox
          options={options}
          selectedValue={selectedValue}
          disabled={options.length <= 0}
          hasError={elemenErrorMessage !== undefined}
          onChange={(changedValue) => {
            if (changedValue === undefined) {
              onChange({
                ...formElementState,
                contentStates: []
              })
            } else {
              onChange({
                ...formElementState,
                contentStates: [
                  formElementState.type === V1FormElementStateType.Text
                    ? {
                        text: changedValue as string
                      }
                    : {
                        uint32: Number(changedValue)
                      }
                ]
              })
            }
          }}
          label={{ right: selectBoxUiIFM.unit }}
          style={style}
        />
        {elemenErrorMessage !== undefined && (
          <InputErrorLabel message={elemenErrorMessage} />
        )}
      </div>
    </>
  )
}
