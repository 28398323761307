import ScreenByType from '@/feature/Builder/Screen/ScreenByType'
import { uniqueId } from 'lodash'
import { V1ScreenType, type V1Component } from 'my-recruiter-app-ts-if'
import JobSeekerCreateMessageBuilder from '../ComposeJobSeekerMessageBuilder'
import { ComposeMessageContextProvider } from '@/feature/Builder/Component/ComposeMessageWapper'
import { NewMessageBottomBar } from '../component/NewMessageBottomBar'

function ComposeJobSeekerMessagePage(): JSX.Element {
  const handleSendMessage = (): void => {}

  const handleSaveDraft = (): void => {}

  return (
    <ComposeMessageContextProvider>
      <>
        <ScreenByType
          request={{
            screenType: V1ScreenType.ComposeJobSeekerMessage
          }}
          componentBuilder={(component: V1Component) => {
            return (
              <JobSeekerCreateMessageBuilder
                key={`ComposeJobSeekerMessageBuilder_${uniqueId().toString()}`}
                component={component}
              />
            )
          }}
        />
        <NewMessageBottomBar
          handleSaveDraft={handleSendMessage}
          handleSendMessage={handleSaveDraft}
        />
      </>
    </ComposeMessageContextProvider>
  )
}

export default ComposeJobSeekerMessagePage
