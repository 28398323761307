import { SelectorPopup } from '@/components/FormElement/FormElementsSelectorPopupButtons/SelectorElementPopUp'
import { FormElementsSelectorPopupContainer } from './FormElementsSelectorPopupContainer/FormElementsSelectorPopupContainer'
import { memo } from 'react'

interface Props {
  viewId: string
  isOpen: boolean
  onClose: () => void
  onSelect: (items: string[], text: string) => void
  defaultSelected?: string[]
  defaultTabSelected?: number
}

const SelectorPopContainer = ({
  viewId,
  isOpen,
  onClose,
  onSelect,
  defaultSelected = [],
  defaultTabSelected = 0
}: Props): JSX.Element => {
  return (
    <SelectorPopup isOpen={isOpen} onClickModalBackground={onClose}>
      <FormElementsSelectorPopupContainer
        viewId={viewId}
        onSelect={onSelect}
        defaultSelected={defaultSelected}
        onClose={onClose}
        defaultTabSelected={defaultTabSelected}
      />
    </SelectorPopup>
  )
}

export default memo(SelectorPopContainer)
