import type { AppTheme } from '@/hooks/useApp'
import { createUseStyles } from 'react-jss'

const AppFont = {
  notoSansJp: 'Noto Sans JP'
}

const AppFontWeight = {
  w700: 700,
  w400: 400
}
const useGlobalStyles = createUseStyles((theme: AppTheme) => ({
  spHerotext: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w700,
    fontSize: 24
  },
  spHeadline1text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w700,
    fontSize: 19
  },
  spHeadline2text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w700,
    fontSize: 16
  },
  spHeadline3text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w700,
    fontSize: 14
  },
  spHeadline4text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w700,
    fontSize: 12
  },
  spHeadline5text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w700,
    fontSize: 10
  },
  spBody1text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w400,
    fontSize: 16
  },
  spBody2text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w400,
    fontSize: 14
  },
  spBody3text: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w400,
    fontSize: 12
  },
  spCaptiontext: {
    fontFamily: AppFont.notoSansJp,
    fontWeight: AppFontWeight.w400,
    fontSize: 10
  },
  border: {
    border: `1px solid ${theme.frameColor.color ?? 'white'}`,
    background: theme.whiteTextColor.color ?? 'white'
  },
  borderError: {
    border: `1px solid ${theme.errorMainColor.color ?? 'red'}`,
    background: theme.errorUltralightColor.color ?? 'F7E6E9'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    maxWidth: 1080,
    minWidth: 280,
    width: '100%',
    margin: '0px auto',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0
  }
}))

export default useGlobalStyles
