import {
  type V1Screen,
  type V1AppColorTheme,
  type V1TabBarView,
  type V1ViewTabBar
} from 'my-recruiter-app-ts-if'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { fontSize } from '@/styles/font'
import ScreenByType from '../Screen/ScreenByType'
import TabView from '@/components/base/TabView/TabView'
import { useApp } from '@/hooks/useApp'
import styled from 'styled-components'
import { memo } from 'react'

const StyledDiv = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`
const StyledTabList = styled(TabList)<{ activebordercolor: V1AppColorTheme }>`
  display: flex;
  .selected {
    border-bottom: ${({ activebordercolor }) =>
      `2px solid ${activebordercolor.color ?? 'white'}`};
    font-weight: 700;
  }

  // react tab default
  margin: 0 0 10px;
  padding: 0;
  // border-bottom: 1px solid #aaa;
  font-size: ${fontSize.S};
`
const StyledTab = styled(Tab)`
  &:focus {
    outline: none;
  }
  flex-grow: 1;
  text-align: center;

  // react tab default
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px;
  cursor: pointer;
`

interface TabBarViewProps {
  view: V1TabBarView
  defaultIndex?: number
  screenBuilder?: (screen: V1Screen, tabBar?: V1ViewTabBar) => JSX.Element[]
  customListTab?: any
}

export const TabBarView = ({
  defaultIndex,
  view,
  screenBuilder,
  customListTab
}: TabBarViewProps): JSX.Element => {
  const { tabBars, screens } = view
  const app = useApp()

  if (tabBars === undefined) return <></>

  return (
    <StyledDiv>
      <Tabs defaultIndex={defaultIndex}>
        <StyledTabList
          activebordercolor={app.theme.primaryMainColor}
          style={customListTab}
        >
          {tabBars.map((e, index) => (
            <StyledTab
              selectedClassName="selected"
              key={(e.screenType?.toString() ?? '') + index.toString()}
            >
              {e.text}
            </StyledTab>
          ))}
        </StyledTabList>
        {tabBars.map((e, index) => (
          <TabPanel key={(e.screenType?.toString() ?? '') + index.toString()}>
            {screenBuilder !== undefined &&
            screens !== undefined &&
            screens.length > index ? (
              screenBuilder(screens[index], e)
            ) : (
              <ScreenByType screenType={e.screenType} />
            )}
          </TabPanel>
        ))}
      </Tabs>
    </StyledDiv>
  )
}

export default memo(TabView)
