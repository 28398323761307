import { getViewById } from '@/api/view-service-api'
import { type V1View } from 'my-recruiter-app-ts-if'
import { useContext, useEffect, useState } from 'react'
import FormElementsSelectorPopup from '@/components/FormElement/FormElementsSelectorPopupButtons/FormElementsSelectorPopup/FormElementsSelectorPopup'
import {
  FormElementsSelectorContext,
  FormElementsSelectorProvider
} from './context'
import { FormElementsSelectorBackContainer } from './FormElementsSelectorBottomContainer'
import { addDefaultValues, getSelectedLabels } from './fn'

interface Props {
  viewId: string
  onSelect: (items: string[], text: string) => void
  onClose: () => void
  defaultSelected?: string[]
  defaultTabSelected?: number
}

export const FormElementsSelectorPopupContainer = ({
  viewId,
  defaultTabSelected,
  onSelect,
  defaultSelected,
  onClose
}: Props): JSX.Element => {
  const [viewData, setViewData] = useState<V1View | undefined>(undefined)

  useEffect(() => {
    void onLoadData()
    return () => {}
  }, [])

  const onLoadData = async (): Promise<void> => {
    const view = await getViewById(viewId)
    setViewData(view)
  }

  if (viewData === undefined) return <></>

  return (
    <FormElementsSelectorProvider>
      <FormElementsSelectorPopupContent
        view={viewData}
        defaultTabSelected={defaultTabSelected}
        defaultSelected={defaultSelected}
        onSelect={onSelect}
        onClose={onClose}
      />
    </FormElementsSelectorProvider>
  )
}

const FormElementsSelectorPopupContent = ({
  view,
  defaultTabSelected,
  onSelect,
  defaultSelected,
  onClose
}: {
  view?: V1View
  defaultTabSelected?: number
  defaultSelected?: string[]
  onSelect: (items: string[], text: string) => void
  onClose: () => void
}): JSX.Element => {
  if (view === undefined) return <></>
  const { state, dispatch } = useContext(FormElementsSelectorContext)

  useEffect(() => {
    addDefaultValues(defaultSelected ?? [], dispatch)
    return () => {}
  }, [])

  return (
    <FormElementsSelectorPopup
      view={view}
      defaultTabSelected={defaultTabSelected}
      backButton={<FormElementsSelectorBackContainer />}
      onSubmit={() => {
        onSelect(state.values, getSelectedLabels(state).join(' '))
        onClose()
      }}
      onClose={onClose}
      paddingTop={state.items.length < 2 ? '24px' : '0px'}
    />
  )
}
