import spaceStyle from '@/styles/space'
import {
  type V1FormElementUiCheckBoxList,
  type V1FormElementState,
  V1FormElementStateType
} from 'my-recruiter-app-ts-if'
import { useState, memo } from 'react'
import styled from 'styled-components'
import { Checkbox } from '../base/Form/Checkbox'
import { Row } from '../base/Layout'
import { Text3 } from '../base/Text'
import type OptionType from './OptionType'
import { Block } from '../base/Block'
import { align } from '@/styles/display'
import { CheckboxSize } from '../base/Form/Checkbox/Checkbox'
import { device } from '@/styles/device'

interface FormElementCheckBoxListSimpleOption<T extends OptionType> {
  value: T
  label: string
  width?: string
}

interface FormElementCheckBoxListSimpleProps<T extends OptionType> {
  options: Array<FormElementCheckBoxListSimpleOption<T>>
  initialSelectedValues: T[]
  onChange: (newSelectedValues: T[]) => void
}

const CheckboxListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 12px;
  }
`

function FormElementCheckBoxListSimple<T extends OptionType>({
  options,
  initialSelectedValues,
  onChange
}: FormElementCheckBoxListSimpleProps<T>): JSX.Element {
  const [selectedValuesState, setSelectedValuesState] = useState<T[]>(
    initialSelectedValues
  )

  return (
    <CheckboxListContainer>
      {options.map((option) => {
        const checked = selectedValuesState.includes(option.value)
        return (
          <Row key={option.label}>
            <Checkbox
              checked={checked}
              onChange={() => {
                let newSelectedValueState: T[]

                if (checked) {
                  newSelectedValueState = selectedValuesState.filter(
                    (e) => e !== option.value
                  )
                } else {
                  newSelectedValueState = [...selectedValuesState, option.value]
                }

                setSelectedValuesState(newSelectedValueState)
                onChange(newSelectedValueState)
              }}
              size={CheckboxSize.Size14}
            >
              <Text3 margin={{ all: spaceStyle.NONE }}>{option.label}</Text3>
            </Checkbox>
          </Row>
        )
      })}
    </CheckboxListContainer>
  )
}

export interface FormElementCheckboxListSimpleProps {
  isEdit: boolean
  checkBoxListSimpleUiIFM: V1FormElementUiCheckBoxList
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementCheckboxListSimple = ({
  isEdit,
  checkBoxListSimpleUiIFM,
  formElementState,
  onChange
}: FormElementCheckboxListSimpleProps): JSX.Element => {
  if (
    formElementState.type === V1FormElementStateType.Uint32List ||
    formElementState.type === V1FormElementStateType.Uint32 ||
    formElementState.type === V1FormElementStateType.TextList ||
    formElementState.type === V1FormElementStateType.Text
  ) {
    const options: Array<FormElementCheckBoxListSimpleOption<number | string>> =
      checkBoxListSimpleUiIFM.contents?.map(
        (e): FormElementCheckBoxListSimpleOption<number | string> => {
          return {
            label: e.label ?? '',
            value:
              e.state?.uint32 != null ? e.state?.uint32 : (e.state?.text ?? '')
          }
        }
      ) ?? []

    const selectedValues: Array<string | number> =
      formElementState.contentStates?.map((e) => {
        return e.uint32 != null ? e.uint32 : (e.text ?? '')
      }) ?? []

    if (!isEdit) {
      const selectedLabels = selectedValues
        .map((value) => {
          const matchingOption = options.find(
            (option) => option.value === value
          )
          return matchingOption?.label
        })
        .filter((label) => label !== undefined)

      return (
        <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
          {selectedLabels.join('、')}
        </Text3>
      )
    }

    return (
      <Block>
        <FormElementCheckBoxListSimple
          options={options}
          initialSelectedValues={selectedValues}
          onChange={(selectedValues) => {
            onChange({
              ...formElementState,
              contentStates: selectedValues.map((selectedValue) => {
                return typeof selectedValue === 'number'
                  ? {
                      uint32: selectedValue
                    }
                  : {
                      text: selectedValue
                    }
              })
            })
          }}
        />
      </Block>
    )
  }

  return (
    <>
      <Block>
        <Checkbox>
          <Text3 margin={{ left: spaceStyle.M }}>指定なし</Text3>
        </Checkbox>
      </Block>
    </>
  )
}

export default memo(FormElementCheckboxListSimple)
