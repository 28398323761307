import { memo } from 'react'

import {
  type V1FormElementState,
  type V1FormElementUiOccupationCategorySelect
} from 'my-recruiter-app-ts-if'
import { AccentButton } from '@/components/base/Button'
import spaceStyle from '@/styles/space'
import { commonBorder } from '@/styles/border'
import borderRadius from '@/styles/border_radius'
import { fontSize, fontWeight } from '@/styles/font'
import { useApp } from '@/hooks/useApp'

export interface FormElementOccupationCategoryRadioListProps {
  occupationCategoryUiIFM: V1FormElementUiOccupationCategorySelect
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onClick?: (element: V1FormElementUiOccupationCategorySelect) => void
}

const FormElementOccupationCategoryRadioList = ({
  occupationCategoryUiIFM,
  formElementState,
  onClick
}: FormElementOccupationCategoryRadioListProps): JSX.Element => {
  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32_LIST') {
    const app = useApp()

    return (
      <>
        <AccentButton
          width="100%"
          style={{
            boxShadow: 'none',
            justifyContent: 'left',
            boxSizing: 'border-box',
            flex: 'none'
          }}
          padding={{ vertical: spaceStyle.L, horizontal: spaceStyle.M }}
          key={occupationCategoryUiIFM.name}
          iconProps={{
            iconType:
              occupationCategoryUiIFM.iconType ?? 'ICON_TYPE_UNSPECIFIED',
            iconStyle: { theme: app.theme.primaryMainColor }
          }}
          border={{ ...commonBorder, color: app.theme.frameColor }}
          borderRadius={borderRadius.M}
          fontSize={fontSize.S}
          fontWeight={fontWeight.Regular}
          textColor={app.theme.mainTextColor}
          onClick={(event) => {
            event.preventDefault()
            if (onClick != null) {
              onClick(occupationCategoryUiIFM)
            }
          }}
        >
          {occupationCategoryUiIFM.name ?? ''}
        </AccentButton>
      </>
    )
  }

  return (
    <>
      <div>not implemented</div>
    </>
  )
}

export default memo(FormElementOccupationCategoryRadioList)
