import _ from 'lodash'
import {
  type V1SelectorElement,
  type V1SelectorComponent,
  SelectorComponentSelectType
} from 'my-recruiter-app-ts-if'
import { useReducer } from 'react'

export interface FormElementsSelectorAction<T> {
  type: string
  payload?: T
}

export const FETCHING_DATA_SELECTOR_ELEMENTS = 'FETCHING_DATA_SELECTOR_ELEMENTS'
export const ADD_DEFAULT_VALUES_SELECTOR_ELEMENTS =
  'ADD_DEFAULT_VALUES_SELECTOR_ELEMENTS'
export const ADD_SELECTOR_ELEMENTS = 'ADD_SELECTOR_ELEMENTS'
export const ON_SELECT = 'ON_SELECT'
export const ON_SELECT_ALL = 'ON_SELECT_ALL'
export const RESET = 'RESET'
export const ON_RESET_SELECT = 'ON_RESET_SELECT'
export const ON_BACK = 'ON_BACK'

export interface FormElementsSelectorState {
  isLoading: boolean
  items: V1SelectorComponent[]
  allElements: V1SelectorElement[]
  values: string[]
  targetElements: V1SelectorElement[]
}

export const initFormElementsSelectorState: {
  state: FormElementsSelectorState
  dispatch: React.Dispatch<FormElementsSelectorAction<any>>
} = {
  state: {
    isLoading: false,
    values: [],
    items: [],
    targetElements: [],
    allElements: []
  },
  dispatch: (a: FormElementsSelectorAction<any>) => {}
}

const reducer = (
  state: FormElementsSelectorState,
  action: FormElementsSelectorAction<any>
): FormElementsSelectorState => {
  switch (action.type) {
    case FETCHING_DATA_SELECTOR_ELEMENTS: {
      if (action.payload !== undefined) {
        return {
          ...state,
          isLoading: !state.isLoading,
          targetElements: [
            ...state.targetElements,
            action.payload as V1SelectorElement
          ]
        }
      }
      return { ...state, isLoading: !state.isLoading }
    }
    case ADD_DEFAULT_VALUES_SELECTOR_ELEMENTS: {
      const values = action.payload as string[]
      return {
        ...state,
        values
      }
    }
    case RESET: {
      return { ...initFormElementsSelectorState.state }
    }
    case ON_RESET_SELECT: {
      return { ...state, values: [] }
    }
    case ON_BACK: {
      return {
        ...state,
        targetElements: _.dropRight(state.targetElements),
        items: _.dropRight(state.items)
      }
    }

    case ADD_SELECTOR_ELEMENTS: {
      if (action.payload === undefined) return { ...state }
      const { elements, selectType } = action.payload as V1SelectorComponent
      switch (selectType) {
        case SelectorComponentSelectType.Radio:
        case SelectorComponentSelectType.Checkbox:
          return {
            ...state,
            items: [...state.items, action.payload as V1SelectorComponent],
            allElements: _.uniqBy(
              [...state.allElements, ...(elements ?? [])],
              'id'
            )
          }
      }
      return {
        ...state,
        items: [...state.items, action.payload as V1SelectorComponent]
      }
    }

    case ON_SELECT: {
      const item = _.last(state.items)
      if (action.payload === undefined || item === undefined)
        return { ...state }
      const isSelected =
        _.findIndex(
          state.values,
          (v) => v === (action.payload as V1SelectorElement).data
        ) > -1
      switch (item.selectType) {
        case SelectorComponentSelectType.Checkbox:
          return {
            ...state,
            values: isSelected
              ? [
                  ..._.filter(
                    state.values,
                    (r) => r !== (action.payload as V1SelectorElement).data
                  )
                ]
              : [
                  ...state.values,
                  (action.payload as V1SelectorElement).data ?? ''
                ]
          }
        case SelectorComponentSelectType.Radio:
          return {
            ...state,
            values: isSelected
              ? []
              : [(action.payload as V1SelectorElement).data ?? '']
          }
        default:
          break
      }
      return {
        ...state
      }
    }
    case ON_SELECT_ALL: {
      const { items } = state
      if (_.isBoolean(action.payload)) {
        const options = (_.last(items)?.elements ?? []).filter(
          (e) => e.id !== undefined
        )

        if (action.payload) {
          return {
            ...state,
            values: _.unionWith(
              [...options.map((o) => o.data ?? '')],
              [...state.values],
              _.isEqual
            )
          }
        }
        return {
          ...state,
          values: [
            ...state.values.filter(
              (value) => _.findIndex(options, (v) => v.data === value) === -1
            )
          ]
        }
      }
      return { ...state }
    }
  }
  return state
}

export const useFormElementsSelector = (): [
  FormElementsSelectorState,
  React.Dispatch<FormElementsSelectorAction<any>>
] => {
  return useReducer(reducer, initFormElementsSelectorState.state)
}
