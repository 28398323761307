import { fontWeight } from '@/styles/font'
import { Text2 } from '../base/Text'
import { type ReactNode } from 'react'
import { useApp } from '@/hooks/useApp'
import spaceStyle from '@/styles/space'
import textAlign from '@/styles/text_align'

interface Props {
  children: ReactNode
}
const FormLabel = ({ children }: Props): JSX.Element => {
  const app = useApp()
  return (
    <Text2
      margin={{ vertical: spaceStyle.S }}
      textAlign={textAlign.LEFT}
      textColor={app.theme.mainTextColor}
      fontWeight={fontWeight.Bold}
    >
      {children}
    </Text2>
  )
}

export default FormLabel
