import {
  type StorageServiceGetPresingedURLForPutRequest,
  type V1GetPresingedURLForPutResponse
} from 'my-recruiter-app-ts-if'
import { storageServiceApi } from '@/api/storage-service-api'
import {
  useQueries,
  useQuery,
  type UseQueryResult
} from '@tanstack/react-query'
import { showErrorToast } from '@/components/SnackBar/BaseSnackBar'

export const GetPresingedURLForPut = async (
  requestParameters: StorageServiceGetPresingedURLForPutRequest
): Promise<V1GetPresingedURLForPutResponse> => {
  const response =
    await storageServiceApi.storageServiceGetPresingedURLForPut(
      requestParameters
    )
  return response
}

interface UseGetPresingedURLForPutResponse {
  data: V1GetPresingedURLForPutResponse | undefined
  isLoading: boolean
}
export const useGetPresingedURLForPut = (
  uploadFile: File | null
): UseGetPresingedURLForPutResponse => {
  const { data, isLoading } = useQuery(
    ['get_put_presinged_url', uploadFile?.name],
    async () =>
      await GetPresingedURLForPut({ fileName: uploadFile?.name ?? '' }),
    {
      enabled: uploadFile !== null,
      onError: (e) => {
        // TODO: エラーハンドリング
        console.error(e)
        showErrorToast('useGetPresingedURLForPut failed')
      }
    }
  )
  return {
    data,
    isLoading
  }
}

export const useGetPresingedURLsForPut = (
  uploadFiles: File[]
): Array<UseQueryResult<V1GetPresingedURLForPutResponse, unknown>> => {
  const results = useQueries({
    queries: uploadFiles.map((uploadFile) => {
      return {
        queryKey: ['get_put_presinged_urls', uploadFile?.name],
        queryFn: async () =>
          await GetPresingedURLForPut({ fileName: uploadFile?.name ?? '' }),
        enabled: uploadFile !== null,
        onError: (e: any) => {
          // TODO: エラーハンドリング
          console.error(e)
          showErrorToast('useGetPresingedURLsForPut failed')
        }
      }
    })
  })

  return results
}
