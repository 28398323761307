export interface UploadTempFileToS3Request {
  presignedUrl: string
  file: File
}

export const uploadTempFileToS3 = async (
  requestParameters: UploadTempFileToS3Request
): Promise<void> => {
  try {
    const response = await fetch(requestParameters.presignedUrl, {
      method: 'PUT',
      body: requestParameters.file,
      headers: {
        'Content-Type': requestParameters.file.type
      }
    })
    if (!response.ok) {
      // TODO: エラーハンドリング
      throw new Error(`S3アップロード処理に失敗しました。 ${response.status}`)
    }
  } catch (error) {
    // TODO: エラーハンドリング
    console.error('Error uploadTempFileToS3', error)
  }
}
