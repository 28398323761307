import { memo, useState } from 'react'

import {
  type V1FormElement,
  type V1FormElementUiSelectorPopupButtons,
  type V1FormElementState,
  type V1FormElementContentState,
  V1FormElementStateType
} from 'my-recruiter-app-ts-if'
import { Row } from '../../base/Layout'
import { commonBorder } from '@/styles/border'
import { useApp } from '@/hooks/useApp'
import borderRadius from '@/styles/border_radius'
import spaceStyle from '@/styles/space'
import { fontSize, fontWeight } from '@/styles/font'
import { AccentButton } from '../../base/Button'
import { Block } from '../../base/Block'
import _ from 'lodash'
import SelectorPopContainer from '@/feature/Builder/Component/containers/SelectorPopupContainer'

export interface FormElementSelectorPopupButtonsProps {
  form: V1FormElement
  onChange: (newState: V1FormElementState) => void
}

const FormElementSelectorPopupButtons = ({
  form,
  onChange
}: FormElementSelectorPopupButtonsProps): JSX.Element => {
  const app = useApp()
  const { ui } = form
  const { buttons } = ui as V1FormElementUiSelectorPopupButtons
  const [popupIndex, setPopupIndex] = useState<number | undefined>(undefined)

  const items = buttons ?? []

  const defaultSelected = (form.state?.contentStates ?? []).map((e) => {
    switch (form.state?.type) {
      case V1FormElementStateType.Uint32:
      case V1FormElementStateType.Uint32List:
        return e.uint32?.toString() ?? ''
      case V1FormElementStateType.Text:
      case V1FormElementStateType.TextList:
        return e.text ?? ''
      case V1FormElementStateType.Bool:
      case V1FormElementStateType.BoolList:
        return e.bool?.toString() ?? ''
      default:
        return ''
    }
  })

  return (
    <>
      <Row gutter={8}>
        {items.map((e, index) => {
          return (
            <Block key={e.label} style={{ flex: 1 }}>
              <AccentButton
                width="100%"
                style={{
                  boxShadow: 'none',
                  justifyContent: 'left',
                  boxSizing: 'border-box',
                  flex: 'none'
                }}
                padding={{ vertical: spaceStyle.L, horizontal: spaceStyle.M }}
                iconProps={{
                  iconType: e.iconType ?? 'ICON_TYPE_UNSPECIFIED',
                  iconStyle: { theme: app.theme.primaryMainColor }
                }}
                onClick={(event) => {
                  event.preventDefault()
                  setPopupIndex(index)
                }}
                border={{ ...commonBorder, color: app.theme.frameColor }}
                borderRadius={borderRadius.M}
                fontSize={fontSize.S}
                fontWeight={fontWeight.Regular}
                textColor={app.theme.mainTextColor}
              >
                {e.label ?? ''}
              </AccentButton>
            </Block>
          )
        })}
      </Row>
      {popupIndex !== undefined &&
      items[popupIndex].popup?.viewId !== undefined ? (
        <SelectorPopContainer
          viewId={items[popupIndex].popup?.viewId ?? ''}
          onSelect={(items) => {
            const contentStates: V1FormElementContentState[] = items.map(
              (item) => {
                switch (form.state?.type) {
                  case V1FormElementStateType.Uint32:
                  case V1FormElementStateType.Uint32List:
                    return { uint32: _.parseInt(item) }
                  case V1FormElementStateType.Text:
                  case V1FormElementStateType.TextList:
                    return { text: item }
                  case V1FormElementStateType.Bool:
                  case V1FormElementStateType.BoolList:
                    return {
                      bool:
                        item.toLowerCase() === 'true' ||
                        item.toLowerCase() === '1'
                    }
                  default:
                    break
                }
                return {}
              }
            )
            const state: V1FormElementState = {
              ...form.state,
              contentStates
            }
            onChange(state)
          }}
          defaultTabSelected={popupIndex ?? 0}
          defaultSelected={defaultSelected}
          onClose={() => {
            setPopupIndex(undefined)
          }}
          isOpen={popupIndex !== undefined}
        />
      ) : undefined}
    </>
  )
}

export default memo(FormElementSelectorPopupButtons)
