import type React from 'react'
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState
} from 'react'

interface ComposeMessageContextType {
  listFile: string[]
  setListFile: Dispatch<SetStateAction<string[]>>
  isEdit: boolean
  setIsEdit: Dispatch<SetStateAction<boolean>>
}

export const ComposeMessageContext = createContext<ComposeMessageContextType>({
  listFile: [],
  setListFile: () => {},
  isEdit: true,
  setIsEdit: () => {}
})

export const ComposeMessageContextProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [listFile, setListFile] = useState<string[]>([])
  const [isEdit, setIsEdit] = useState<boolean>(true)

  return (
    <ComposeMessageContext.Provider
      value={{ listFile, setListFile, isEdit, setIsEdit }}
    >
      {children}
    </ComposeMessageContext.Provider>
  )
}
