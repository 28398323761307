import { memo } from 'react'
import styled from 'styled-components'
import { Icon, type IconProps } from '../Icon'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import spaceStyle, { buildSpaceStyle, type SpacingProps } from '@/styles/space'
import {
  fontSize,
  fontWeight,
  type FontWeightType,
  type FontSizeKeyType
} from '@/styles/font'
import borderLine, { type BorderProps, buildBorderStyle } from '@/styles/border'
import { useApp } from '@/hooks/useApp'

export const buttonSize = {
  S: '36px',
  M: '44px',
  L: '64px'
} as const

export type ButtonSizeKeyType = (typeof buttonSize)[keyof typeof buttonSize]

export enum IconPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right'
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  cursor?: string
  bgColor?: V1AppColorTheme
  bgColorDisabled?: V1AppColorTheme
  textColor?: V1AppColorTheme
  borderColor?: V1AppColorTheme
  border?: BorderProps
  borderRadius?: string
  fontSize?: FontSizeKeyType
  fontWeight?: FontWeightType
  margin?: SpacingProps
  padding?: SpacingProps
  disableClickAction?: boolean
  iconProps?: IconProps & { position?: IconPosition }
  maxWidth?: string
  width?: string
  height?: ButtonSizeKeyType
  outlined?: boolean
  shadown?: boolean
  hoverBgColor?: V1AppColorTheme
  childStyle?: React.CSSProperties
}

const StyledButton = styled.button<ButtonProps>`
  flex: 1;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-align: center;
  background-color: ${(props) => {
    if (
      (props.disableClickAction ?? false) &&
      props.bgColorDisabled !== undefined
    ) {
      // disabled button background-color
      return props.bgColorDisabled?.color
    }
    return props.bgColor?.color !== undefined ? props.bgColor?.color : 'white'
  }};
  color: ${(props) => {
    if (props.disableClickAction ?? false) {
      // disabled button text color
      return 'white'
    }
    return props.textColor?.color !== undefined
      ? props.textColor?.color
      : 'black'
  }};
  border: ${(props) => {
    return props.border !== undefined
      ? buildBorderStyle(props.border)
      : borderLine.NONE
  }};
  border-color: ${(props) =>
    props.borderColor?.color !== undefined
      ? props.borderColor?.color
      : undefined};
  display: ${(props) =>
    props.iconProps?.position !== undefined &&
    [IconPosition.top, IconPosition.bottom].includes(props.iconProps?.position)
      ? 'block'
      : 'flex'};
  margin: ${(props) => {
    return props.margin !== undefined ? buildSpaceStyle(props.margin) : ''
  }};
  padding: ${(props) => {
    return props.padding !== undefined
      ? buildSpaceStyle(props.padding)
      : spaceStyle.L
  }};
  border-radius: ${(props) =>
    props.borderRadius !== undefined ? props.borderRadius : '10000px'};
  font-size: ${(props) =>
    props.fontSize !== undefined ? props.fontSize : fontSize.M};
  font-weight: ${(props) =>
    props.fontWeight !== undefined ? props.fontWeight : fontWeight.Regular};
  cursor: ${(props) => (props.cursor === undefined ? 'pointer' : props.cursor)};
  box-shadow: ${(props) =>
    (props.disabled ?? false)
      ? 'none'
      : (props.shadown ?? true)
        ? '0 4px 6px rgba(0, 0, 0, 0.1)'
        : 'none'};

  &:active {
    box-shadow: ${(props) =>
      (props.shadown ?? true)
        ? props.disableClickAction !== undefined && props.disableClickAction
          ? '0 4px 6px rgba(0, 0, 0, 0.1)'
          : '0 2px 4px rgba(0, 0, 0, 0.1)'
        : 'none'};
    transform: ${(props) =>
      props.disableClickAction !== undefined && props.disableClickAction
        ? 'none'
        : 'translateY(1px)'};
  }

  &:hover {
    ${(props) =>
      props.hoverBgColor !== undefined
        ? `background-color: ${props.hoverBgColor?.color ?? 'transparent'}`
        : ''};
  }

  max-width: ${(props) =>
    props.maxWidth === undefined ? 'auto' : props.maxWidth};
  width: ${(props) => (props.width === undefined ? 'auto' : props.width)};
  height: ${(props) => (props.height === undefined ? 'auto' : props.height)};
`

const BaseButton = (props: ButtonProps): JSX.Element => {
  const app = useApp()
  const { children } = props
  return (
    <>
      <StyledButton
        {...props}
        bgColorDisabled={app.theme.disabledColor}
        disabled={props.disableClickAction}
      >
        {props.iconProps != null &&
          [IconPosition.top, IconPosition.left, undefined].includes(
            props.iconProps.position
          ) && (
            <Icon
              iconType={props.iconProps.iconType}
              iconStyle={{
                margin: {
                  right:
                    props.iconProps.iconStyle?.margin?.right !== undefined
                      ? props.iconProps.iconStyle?.margin?.right
                      : [IconPosition.left, undefined].includes(
                            props.iconProps.position
                          )
                        ? spaceStyle.S
                        : spaceStyle.NONE,
                  bottom:
                    props.iconProps.iconStyle?.margin?.bottom !== undefined
                      ? props.iconProps.iconStyle?.margin?.bottom
                      : props.iconProps.position !== undefined &&
                          [IconPosition.top].includes(props.iconProps.position)
                        ? spaceStyle.S
                        : spaceStyle.NONE
                },
                fontSize: props.iconProps.iconStyle?.fontSize,
                fontWeight: props.iconProps.iconStyle?.fontWeight,
                theme: props.iconProps.iconStyle?.theme
              }}
            />
          )}
        <div style={props.childStyle}>{children}</div>
        {props.iconProps?.position != null &&
          [IconPosition.bottom, IconPosition.right].includes(
            props.iconProps.position
          ) && (
            <Icon
              iconType={props.iconProps.iconType}
              iconStyle={{
                margin: {
                  left:
                    props.iconProps.iconStyle?.margin?.left !== undefined
                      ? props.iconProps.iconStyle?.margin?.left
                      : [IconPosition.right, undefined].includes(
                            props.iconProps.position
                          )
                        ? spaceStyle.S
                        : spaceStyle.NONE,
                  top:
                    props.iconProps.iconStyle?.margin?.top !== undefined
                      ? props.iconProps.iconStyle?.margin?.top
                      : props.iconProps.position !== undefined &&
                          [IconPosition.bottom].includes(
                            props.iconProps.position
                          )
                        ? spaceStyle.S
                        : spaceStyle.NONE
                },
                fontSize: props.iconProps.iconStyle?.fontSize,
                fontWeight: props.iconProps.iconStyle?.fontWeight,
                theme: props.iconProps.iconStyle?.theme
              }}
            />
          )}
      </StyledButton>
    </>
  )
}

export default memo(BaseButton)
