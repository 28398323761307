// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-extraneous-class
class Validator {
  static isEmail(text: string): boolean {
    const expression: RegExp =
      /^([a-z0-9+_-]+)(\.[a-z0-9+_-]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/

    return expression.test(text)
  }

  static isPassword(text: string): boolean {
    const expression: RegExp =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,20}$/

    return expression.test(text)
  }

  static isZipCode(text: string): boolean {
    const expression: RegExp = /^\d{3}-?\d{4}$/
    return expression.test(text)
  }
}

export default Validator
