import { memo } from 'react'
import InputText from './InputText'
import Validator from './InputValidate'

interface EmailInputProps {
  name: string
  label?: string
  placeholder?: string
  maxWidth?: number
  onChange: (value: string) => void
  defaultValue?: string | number
}

const EmailInput = (props: EmailInputProps): JSX.Element => {
  return (
    <InputText
      {...props}
      label={props.label ?? 'メールアドレス'}
      type={'email'}
      onChange={props.onChange}
      onValidateError={(text) => {
        const valid = Validator.isEmail(text)
        return valid
          ? undefined
          : '正しいメールアドレスの形式で入力してください'
      }}
    />
  )
}

export default memo(EmailInput)
