import { memo } from 'react'
import {
  type V1FormElementUiCheckBoxList,
  type V1FormElementState,
  type V1FormElementContentState
} from 'my-recruiter-app-ts-if'

import type OptionType from './OptionType'
import { Block } from '../base/Block'
import spaceStyle from '@/styles/space'
import borderRadius from '@/styles/border_radius'
import { align, display } from '@/styles/display'
import { commonBorder } from '@/styles/border'
import { useApp } from '@/hooks/useApp'
import { Row } from '../base/Layout'
import Checkbox from '../base/Form/Checkbox/Checkbox'
import { Text3 } from '../base/Text'

interface GenericFormElementCheckBoxListOption<T extends OptionType> {
  value: T
  label: string
  width?: string
}

interface GenericFormElementCheckBoxListProps<T extends OptionType> {
  elementKey: string
  options: Array<GenericFormElementCheckBoxListOption<T>>
  selectedValues: T[]
  onChange: (newSelectedValues: T[]) => void
}

function GenericFormElementCheckboxList<T extends OptionType>({
  elementKey,
  options,
  selectedValues,
  onChange
}: GenericFormElementCheckBoxListProps<T>): JSX.Element {
  const app = useApp()
  const gutter = 8

  return (
    <Row gutter={gutter}>
      {options.map((option, index) => {
        const checked = selectedValues.includes(option.value)
        return (
          <Block
            padding={{ vertical: spaceStyle.L, horizontal: spaceStyle.M }}
            borderRadius={{ all: borderRadius.M }}
            display={display.FLEX}
            key={elementKey + '_' + option.value.toString()}
            border={{
              ...commonBorder,
              color: checked ? app.theme.primaryMainColor : app.theme.frameColor
            }}
            backgroundColor={
              checked ? app.theme.lightGrayColor : app.theme.whiteColor
            }
            width={`calc((100% - ${gutter}px) / 2)`}
            style={{ boxSizing: 'border-box' }}
            textAlign={align.LEFT}
          >
            <div style={{ overflowX: 'auto' }}>
              <Checkbox
                style={{ flex: 1 }}
                checked={checked}
                onChange={() => {
                  let newSelectedValueState: T[]

                  if (checked) {
                    newSelectedValueState = selectedValues.filter(
                      (e) => e !== option.value
                    )
                  } else {
                    newSelectedValueState = [...selectedValues, option.value]
                  }

                  onChange(newSelectedValueState)
                }}
              >
                <Text3 margin={{ left: spaceStyle.M }}>{option.label}</Text3>
              </Checkbox>
            </div>
          </Block>
        )
      })}
    </Row>
  )
}

export interface FormElementCheckboxListProps {
  checkBoxListUiIFM: V1FormElementUiCheckBoxList
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementCheckboxList = ({
  checkBoxListUiIFM,
  formElementState,
  onChange
}: FormElementCheckboxListProps): JSX.Element => {
  if (
    formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32_LIST' ||
    formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32' ||
    formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT' ||
    formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT_LIST'
  ) {
    const getValue = (
      e: V1FormElementContentState | undefined
    ): number | string => {
      if (
        formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32_LIST' ||
        formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32'
      ) {
        return e?.uint32 ?? 0
      } else {
        return e?.text ?? ''
      }
    }

    const options: Array<
      GenericFormElementCheckBoxListOption<number | string>
    > =
      checkBoxListUiIFM.contents?.map((e) => {
        return {
          label: e.label ?? 'label',
          value: getValue(e.state)
        } satisfies GenericFormElementCheckBoxListOption<number | string>
      }) ?? []

    const selectedValues: Array<number | string> =
      formElementState.contentStates?.map((e) => {
        return getValue(e)
      }) ?? []

    return (
      <>
        <div>
          <GenericFormElementCheckboxList
            elementKey={formElementState.key ?? ''}
            options={options}
            selectedValues={selectedValues}
            onChange={(selectedValues) => {
              onChange({
                ...formElementState,
                contentStates: selectedValues.map((e) => {
                  if (
                    formElementState.type ===
                      'FORM_ELEMENT_STATE_TYPE_UINT32_LIST' ||
                    formElementState.type === 'FORM_ELEMENT_STATE_TYPE_UINT32'
                  ) {
                    return {
                      uint32: e as number
                    } satisfies V1FormElementContentState
                  } else {
                    return {
                      text: e as string
                    } satisfies V1FormElementContentState
                  }
                })
              })
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div>
        <Checkbox>
          <Text3 margin={{ left: spaceStyle.M }}>指定なし</Text3>
        </Checkbox>
      </div>
    </>
  )
}

export default memo(FormElementCheckboxList)
