import { type ReactNode, memo } from 'react'
import styled, { css } from 'styled-components'
import { useApp } from '@/hooks/useApp'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'

export enum CheckboxSize {
  Size14 = '14px',
  Size16 = '16px',
  Size20 = '20px'
}

const checkedStyles = {
  [CheckboxSize.Size14]: css`
    left: 4px;
    width: 4px;
    height: 7px;
  `,
  [CheckboxSize.Size16]: css`
    width: 4px;
    height: 8px;
  `,
  [CheckboxSize.Size20]: css`
    left: 6px;
    width: 6px;
    height: 11px;
  `
}

const CheckboxContainer = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  cursor: ${({ disabled }) => ((disabled ?? false) ? 'default' : 'pointer')};
`

const CheckboxCheckmark = styled.span<{
  size: CheckboxSize
  checkedColor: V1AppColorTheme
}>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  background-color: ${({ checkedColor }) => checkedColor.color};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: ${(props) => props.size !== undefined && props.size};
  height: ${(props) => props.size !== undefined && props.size};
  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    ${(props) => checkedStyles[props.size]}
  }
`

const CheckboxCheckmarkContainer = styled.div`
  height: 24px;
  width: 0px;
  visibility: hidden;
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + ${CheckboxCheckmark}::after {
    display: block;
  }
`

interface Props {
  children?: ReactNode
  defaultChecked?: boolean
  checked?: boolean
  value?: string | number | readonly string[]
  disabled?: boolean
  size?: CheckboxSize
  style?: React.CSSProperties
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disableColor?: V1AppColorTheme
}
const Checkbox = ({
  children,
  onChange,
  defaultChecked,
  checked,
  value,
  disabled,
  size = CheckboxSize.Size20,
  style,
  disableColor
}: Props): JSX.Element => {
  const app = useApp()

  let checkedColor = app.theme.primaryMainColor
  if (disabled === true && checked === true) {
    checkedColor = app.theme.inactiveColor
  }
  if (checked === undefined || !checked) {
    checkedColor = app.theme.transparent
  }

  if (disabled === true && disableColor !== undefined) {
    checkedColor = disableColor
  }

  return (
    <CheckboxContainer disabled={disabled} style={style}>
      <CheckboxInput
        type="checkbox"
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        value={value}
        disabled={disabled}
      />
      <CheckboxCheckmark size={size} checkedColor={checkedColor} />
      {/* vvvv Added to ensure minimum height so that checkboxes are not hidden by scrollbars. */}
      <CheckboxCheckmarkContainer>{'　'}</CheckboxCheckmarkContainer>
      {children}
    </CheckboxContainer>
  )
}

export default memo(Checkbox)
