import { Block } from '@/components/base/Block'
import { formElementStateMapKey } from '@/components/Form/uitl'
import FormElement from '@/components/FormElement/FormElement'
import { ComposeMessageContext } from '@/feature/Builder/Component/ComposeMessageWapper'
import { useFormComposeMessage } from '@/hooks/Form/useFormComposeMessage'
import useIsMobile from '@/hooks/useMobile'
import { display } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { type V1FormComponent, type V1Screen } from 'my-recruiter-app-ts-if'
import { useContext } from 'react'

interface Props {
  screen?: V1Screen
  component: V1FormComponent
}

const JobSeekerNewMessageContainer = ({
  screen,
  component
}: Props): JSX.Element => {
  const isMobile = useIsMobile()

  const context = useContext(ComposeMessageContext)

  const { isEdit } = context

  const cpnElements = component?.elements ?? []
  const { elementState, elementErrorMessage, handleUpdateFormElementState } =
    useFormComposeMessage(cpnElements)

  return (
    <>
      <Block
        display={display.GRID}
        padding={{ horizontal: isMobile ? spaceStyle.M : spaceStyle.NONE }}
      >
        {cpnElements.map((element, elementIndex: number) => {
          return (
            <FormElement
              key={`FormElement_${elementIndex}`}
              isEdit={isEdit}
              hiddenLabel={true}
              formElementStyle={{
                selectBox: {
                  height: '36px',
                  padding: {
                    horizontal: spaceStyle.M,
                    vertical: spaceStyle.XS
                  }
                }
              }}
              onChange={(newFormElementState) => {
                void handleUpdateFormElementState(element, newFormElementState)
              }}
              formElement={element}
              formElementState={
                elementState[formElementStateMapKey(element)] ?? element.state
              }
              elemenErrorMessage={
                elementErrorMessage[
                  `${String(element.state?.keyIndex ?? 0)}-${element.state?.key ?? ''}`
                ] ?? undefined
              }
            />
          )
        })}
      </Block>
    </>
  )
}

export default JobSeekerNewMessageContainer
