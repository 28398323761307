import { memo } from 'react'

import {
  type V1FormElementState,
  type V1FormElementUiTextField,
  type V1FormElementContentState
} from 'my-recruiter-app-ts-if'
import {
  InputTextArea,
  type StyledTextareaProps
} from '@/components/base/Form/InputTextArea'
import { Text3 } from '@/components/base/Text'
import { align, display } from '@/styles/display'
import spaceStyle from '@/styles/space'
import { showFormValue } from '../formText'
import { fontWeight } from '@/styles/font'
import textAlign from '@/styles/text_align'
import { Block } from '@/components/base/Block'
import useIsMobile from '@/hooks/useMobile'

export interface FormElementInlineTextAreaFieldProps
  extends StyledTextareaProps {
  label?: string
  isEdit: boolean
  textFieldUiIFM: V1FormElementUiTextField
  formElementState: V1FormElementState
  elemenErrorMessage?: string
  onChange: (newElementState: V1FormElementState) => void
}

const FormElementInlineTextAreaField = ({
  label,
  height,
  isEdit,
  textFieldUiIFM,
  elemenErrorMessage,
  formElementState,
  onChange
}: FormElementInlineTextAreaFieldProps): JSX.Element => {
  const isMobile = useIsMobile()

  const textState = formElementState.contentStates?.at(0)?.text ?? ''

  if (formElementState.type === 'FORM_ELEMENT_STATE_TYPE_TEXT') {
    const onChangeText = (value: string): void => {
      onChange({
        ...formElementState,
        type: formElementState.type,
        contentStates: [
          {
            text: value
          } satisfies V1FormElementContentState
        ]
      })
    }

    const textFieldLabel = (
      <Block width={spaceStyle.XXXXXL}>
        <Text3
          textAlign={textAlign.LEFT}
          fontWeight={fontWeight.Bold}
          margin={{ all: spaceStyle.NONE }}
          padding={{ vertical: spaceStyle.S }}
        >
          {label}
        </Text3>
      </Block>
    )

    return (
      <Block
        display={display.FLEX}
        margin={{ bottom: spaceStyle.LL }}
        padding={{ right: isMobile ? spaceStyle.NONE : spaceStyle.XXXXXL }}
      >
        {textFieldLabel}
        {isEdit ? (
          <InputTextArea
            height={height ?? '172px'}
            value={textState}
            onChange={(value) => {
              onChangeText(value)
            }}
            placeholder={textFieldUiIFM?.placeholder}
            errorMessage={elemenErrorMessage}
          />
        ) : (
          <Block display={display.FLEX} alignItems={align.CENTER}>
            <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
              {showFormValue(textState)}
            </Text3>
          </Block>
        )}
      </Block>
    )
  }

  return <></>
}

export default memo(FormElementInlineTextAreaField)
