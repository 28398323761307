import { align } from '@/styles/display'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { SubButton } from '../base/Button'
import { buttonSize } from '../base/Button/Base'
// import { InputText } from '../base/Form/InputText'
import { Row } from '../base/Layout'
import {
  type V1FormElement,
  type V1FormElementState,
  type V1FormElementUiTextAndSelectorPopup,
  type V1FormElementContentState,
  V1FormElementStateType
} from 'my-recruiter-app-ts-if'
import { Text3 } from '../base/Text'
import _ from 'lodash'
import { useState } from 'react'
import { Block } from '../base/Block'
import borderRadius from '@/styles/border_radius'
import SelectorPopContainer from '@/feature/Builder/Component/containers/SelectorPopupContainer'

export const FormELementTextAndSelectorPopup = ({
  form,
  onChange,
  isEdit,
  formElementState
}: {
  isEdit: boolean
  form: V1FormElement
  formElementState: V1FormElementState
  onChange: (newState: V1FormElementState) => void
}): JSX.Element => {
  const { ui } = form
  const { popup } = ui as V1FormElementUiTextAndSelectorPopup
  const [isOpen, setIsOpen] = useState(false)

  const defaultSelected = (formElementState?.contentStates ?? []).map((e) => {
    switch (formElementState?.type) {
      case V1FormElementStateType.Uint32:
      case V1FormElementStateType.Uint32List:
        return e.uint32?.toString() ?? ''
      case V1FormElementStateType.Text:
      case V1FormElementStateType.TextList:
        return e.text ?? ''
      case V1FormElementStateType.Bool:
      case V1FormElementStateType.BoolList:
        return e.bool?.toString() ?? ''
      default:
        return ''
    }
  })

  const selectedLabel = (formElementState?.contentStates ?? [])
    .map((e) => {
      return e.label?.toString() ?? ''
    })
    .join('/')

  if (!isEdit) {
    return (
      <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
        {selectedLabel}
      </Text3>
    )
  }

  return (
    <>
      <Row gutter={8} align={align.CENTER}>
        <Block
          display="flex"
          height="36px"
          width="267px"
          border
          borderRadius={{ all: borderRadius.M }}
          padding={{ horizontal: spaceStyle.M }}
          alignItems="center"
        >
          <Text3 textAlign={align.LEFT} margin={{ all: spaceStyle.NONE }}>
            {selectedLabel}
          </Text3>
        </Block>
        {/* <InputText
          maxWidth={267}
          name={'name'}
          value={defaultSelected.join(' ')}
          onChange={(value) => {}}
        /> */}
        <SubButton
          maxWidth="72px"
          height={buttonSize.S}
          onClick={() => {
            setIsOpen(true)
          }}
          fontSize={fontSize.S}
          padding={{
            vertical: spaceStyle.M,
            horizontal: spaceStyle.XL
          }}
        >
          検索
        </SubButton>
      </Row>
      {popup?.viewId !== undefined ? (
        <SelectorPopContainer
          isOpen={isOpen}
          viewId={popup?.viewId ?? ''}
          onSelect={(items, text) => {
            setIsOpen(false)
            const contentStates: V1FormElementContentState[] = items.map(
              (item) => {
                switch (formElementState?.type) {
                  case V1FormElementStateType.Uint32:
                  case V1FormElementStateType.Uint32List:
                    return { uint32: _.parseInt(item), label: text }
                  case V1FormElementStateType.Text:
                  case V1FormElementStateType.TextList:
                    return { text: item, label: text }
                  case V1FormElementStateType.Bool:
                  case V1FormElementStateType.BoolList:
                    return {
                      bool:
                        item.toLowerCase() === 'true' ||
                        item.toLowerCase() === '1',
                      label: text
                    }
                  default:
                    break
                }
                return {}
              }
            )
            const state: V1FormElementState = {
              ...formElementState,
              contentStates
            }
            onChange(state)
          }}
          defaultSelected={defaultSelected}
          onClose={() => {
            setIsOpen(false)
          }}
        />
      ) : undefined}
    </>
  )
}
