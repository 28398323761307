import styled from 'styled-components'
import { Row } from '../../Layout'
import spaceStyle from '@/styles/space'
import { useApp } from '@/hooks/useApp'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import { align } from '@/styles/display'
import { type ReactNode } from 'react'
import { uniqueId } from 'lodash'

interface RadioButtonProps {
  inputSize?: string
  checkColor: V1AppColorTheme
  borderColor: V1AppColorTheme
}
const RadioButtonInput = styled.input<RadioButtonProps>`
  margin-top: ${spaceStyle.NONE};
  margin-right: ${spaceStyle.XS};
  width: ${(props) =>
    props.inputSize !== undefined ? props.inputSize : '16px'};
  height: ${(props) =>
    props.inputSize !== undefined ? props.inputSize : '16px'};
  appearance: none;
  outline: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${(props) => props.borderColor.color};
    border-radius: 50%;
    box-sizing: border-box;
  }

  &:checked:before {
    border-color: ${(props) => props.borderColor.color};
  }

  &:checked:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background-color: ${(props) => props.checkColor.color};
    border-radius: 50%;
  }
`

export const radioSize = {
  M: '16px'
} as const
export type RadioSizeKeyType = (typeof radioSize)[keyof typeof radioSize]

export interface RadioOption {
  size?: RadioSizeKeyType
  name: string
  value: string
}
interface Props {
  children: ReactNode
  option?: RadioOption
  checked: boolean
  hasError?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const Radio = ({
  children,
  option,
  checked,
  hasError,
  onChange
}: Props): JSX.Element => {
  const app = useApp()
  const radioButtonId = uniqueId('radioButton_id_')
  return (
    <Row align={align.CENTER}>
      <RadioButtonInput
        id={radioButtonId}
        inputSize={option?.size ?? radioSize.M}
        checkColor={app.theme.primaryMainColor}
        borderColor={
          hasError === true
            ? app.theme.errorMainColor
            : app.theme.borderDarkColor
        }
        type="radio"
        name={option?.name}
        value={option?.value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={radioButtonId}>{children}</label>
    </Row>
  )
}

export default Radio
