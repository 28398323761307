import { moveTmpMessageFilesToPersistence } from '@/api/storage-service-api'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'

export const useMoveTmpMessageFilesToPersistenceMutation =
  (): UseMutationResult<object, unknown, any, unknown> => {
    const mutation = useMutation({
      mutationKey: ['move_tmp_message_files_to_persistence'],
      mutationFn: moveTmpMessageFilesToPersistence
    })

    return mutation
  }
