import { formServiceApi } from '@/api/form-service-api'
import {
  V1FormContentsSyncActionType,
  V1FormElementUiType,
  V1GetMunicipalitiesValueType,
  type AppErrorDetailFormError,
  type V1FormElement,
  type V1FormElementState,
  type V1FormElementUiSelectBox
} from 'my-recruiter-app-ts-if'
import { useEffect, useState } from 'react'

interface UseFormComposeMessage {
  elementState: Record<string, V1FormElementState>
  elementErrorMessage: Record<string, string>
  handleUpdateElementErrorMessage: (errors: AppErrorDetailFormError[]) => void
  handleUpdateFormElementState: (
    formElement: V1FormElement,
    newFormElementState: V1FormElementState
  ) => Promise<void>
}

export const useFormComposeMessage = (
  elements: V1FormElement[] = []
): UseFormComposeMessage => {
  const [elementErrorMessage, setElementErrorMessage] = useState<
    Record<string, string>
  >({})

  const [elementState, setFormElementState] = useState<
    Record<string, V1FormElementState>
  >({})

  const recursiveUpdateFormElementState = (elements: V1FormElement[]): void => {
    elements?.forEach((element) => {
      const state: V1FormElementState = {
        key: `${element.state?.key ?? ''}`,
        keyIndex: element.state?.keyIndex,
        elementObjId: element.state?.elementObjId,
        type: element.state?.type,
        contentStates: element.state?.contentStates
      }
      setFormElementState((prevState) => ({
        ...prevState,
        [`${state?.key ?? ''}-${state.keyIndex ?? 0}`]: state
      }))
    })
  }

  const handleUpdateElementErrorMessage = (
    formErrors: AppErrorDetailFormError[]
  ): void => {
    const tmpelemenErrorMessageMap: Record<string, string> = {}
    if (formErrors.length >= 0) {
      formErrors.forEach((formError) => {
        const index = formError?.index ?? 0
        if (formError.key !== undefined) {
          tmpelemenErrorMessageMap[`${String(index)}-${formError.key}`] =
            formError.message ?? ''
        }
      })
    }
    setElementErrorMessage(tmpelemenErrorMessageMap)
  }

  const handleUpdateFormElementState = async (
    formElement: V1FormElement,
    newFormElementState: V1FormElementState
  ): Promise<void> => {
    const newStateKey = newFormElementState.key
    const newStateKeyIndex = newFormElementState.keyIndex

    if (newStateKey != null) {
      setFormElementState((prevState) => ({
        ...prevState,
        [`${newStateKey}-${newStateKeyIndex ?? 0}`]: newFormElementState
      }))
    }

    switch (formElement?.uiType) {
      case V1FormElementUiType.SelectBox: {
        const ui = formElement.ui as V1FormElementUiSelectBox
        if (ui.linkedFormElement !== undefined) {
          switch (ui.linkedFormElement.formContentsSyncAction) {
            case V1FormContentsSyncActionType.MunicipalitiesValueIsCode:
            case V1FormContentsSyncActionType.MunicipalitiesValueIsText: {
              if (newStateKey != null && elementState !== undefined) {
                const linkedFormElementKey: string =
                  ui.linkedFormElement?.elementKey ?? ''
                const stateKey = `${linkedFormElementKey}-${
                  newStateKeyIndex ?? 0
                }`
                const currentContentState =
                  elementState[stateKey]?.contentStates

                const res =
                  await formServiceApi.formServiceGetMunicipalitiesByPrefectureId(
                    {
                      prefectureId:
                        newFormElementState.contentStates?.at(0)?.text ?? '',
                      valueType:
                        ui.linkedFormElement.formContentsSyncAction ===
                        V1FormContentsSyncActionType.MunicipalitiesValueIsText
                          ? V1GetMunicipalitiesValueType.Text
                          : V1GetMunicipalitiesValueType.Code
                    }
                  )

                let includeContentState = false
                if (currentContentState != null) {
                  includeContentState =
                    res.contents?.some((content) => {
                      return (
                        content.state?.text === currentContentState[0]?.text
                      )
                    }) ?? false
                }

                if (!includeContentState && stateKey != null) {
                  setFormElementState((prevState) => ({
                    ...prevState,
                    [stateKey]: {
                      key: elementState[stateKey]?.key,
                      keyIndex: elementState[stateKey]?.keyIndex,
                      elementObjId: elementState[stateKey]?.elementObjId,
                      type: elementState[stateKey]?.type,
                      contentStates: undefined
                    }
                  }))
                }
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    recursiveUpdateFormElementState(elements ?? [])
  }, [])

  return {
    elementState: elementState ?? {},
    elementErrorMessage: elementErrorMessage ?? {},
    handleUpdateElementErrorMessage,
    handleUpdateFormElementState
  }
}
